// @ts-nocheck
import axios from 'axios';

const apiUrl = process.env.VUE_APP_API_URL;
const jsonUrl = process.env.VUE_APP_JSON_URL;

export async function generateNft(name1: string, name2: string, icon: string, premium: string){
	let data = null

	try{
		await axios.post(apiUrl + "/create", {
			"first-half": name1,
			"second-half": name2,
			"symbol": icon,
			"premium": premium
		}).then(res => {
			data = res.data
		});
	} catch{
		data = null
	}

	return data;
}

export async function getNftData(nftId: string) {
	let data = null

	try{
		await axios.get(jsonUrl + "/" + nftId + ".json").then(res => {
			data = res.data
		});
	} catch{
		data = null
	}

	return data;
}

export async function wertTransaction(click_id: string) {
	let data = null

	try{
		await axios.post(apiUrl + "/wert/transaction", {click_id: click_id}).then(res => {
			data = res.data
		});
	} catch{
		data = null
	}

	return data;
}