<template>
	<div class="faq" id="faq">
		<div class="container container--small">
			<div class="faq_content">
				<div class="faq_content__row faq_content__row--title" v-if="faqContent.title">
					<div class="faq_content__col faq_content__col--fill">
						<div class="faq_title">{{faqContent.title}}</div>
					</div>
				</div>
				<div class="faq_content__row">
					<div class="faq_content__col" v-for="item, index in faqContent.items" :key="index">
						<div class="faq_text">
							<div class="faq_text_title" v-if="item.title" v-html="item.title"></div>
							<p class="faq_text_p" v-if="item.content" v-html="item.content"></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		faqContent: Object,
	}
}
</script>


<style lang="scss">
.faq {
	padding: 80px 0;
	margin-top: 80px;
	background: #f9f9f9;
}
.faq_content__row {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -16px;
	margin-bottom: -24px;
}
.faq_content__row--title {
	margin-bottom: 32px;
	.faq_content__col {
		margin-bottom: 0;
	}
}
.faq_content__col {
	width: 50%;
	padding: 0 16px;
	margin-bottom: 24px;
}
.faq_title {
	font-family: DM Mono;
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 44px;
	color: #253137;
}
.faq_text_p {
	margin: 12px 0;
	font-size: 16px;
	line-height: 24px;
	color: #253137;
	&:last-child {
		margin-bottom: 0;
	}
}
.faq_text_title {
	font-weight: bold;
	font-size: 20px;
	line-height: 28px;
	margin: 0;
}

@media (max-width: 991px) {
	.faq {
		margin-top: 40px;
		padding: 40px 0 32px;
	}
	.faq_title {
		font-size: 20px;
		line-height: 28px;
		margin-bottom: 0;
	}
	.faq_content__col {
		width: 100%;
		margin-bottom: 32px;
	}
	.faq_content__row {
		margin-bottom: -32px;
	}
	.faq_content__row--title {
		margin-bottom: 24px;
	}
	.faq_text_title {
		font-size: 16px;
		line-height: 24px;
	}
	.faq_text_p {
		font-size: 16px;
		line-height: 24px;
	}
}
</style>