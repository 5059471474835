<template>
    <div class="article_canvas_back">
        <div class="article_canvas_back__image" :style="{backgroundImage: `url(`+ require('@/assets/images/nft_back.png') +`)`}"></div>
        <div class="article_canvas_back__content">
            <div class="article_canvas_back__section">
                <div class="canvas_features">
                    <div class="canvas_features__col" v-if="data.name1">
                        <div class="canvas_feature">
                            <div class="canvas_feature__title">Partner</div>
                            <div class="canvas_feature__text">
                                {{data.name1}}
                            </div>
                        </div>
                    </div>
                    <div class="canvas_features__col" v-if="data.name2">
                        <div class="canvas_feature">
                            <div class="canvas_feature__title">Partner</div>
                            <div class="canvas_feature__text">
                                {{data.name2}}
                            </div>
                        </div>
                    </div>
                    <div class="canvas_features__col" v-if="data.collection">
                        <div class="canvas_feature">
                            <div class="canvas_feature__title">Collection</div>
                            <div class="canvas_feature__text">
                                {{data.collection}}
                            </div>
                        </div>
                    </div>
                    <div class="canvas_features__col" v-if="data.icon">
                        <div class="canvas_feature">
                            <div class="canvas_feature__title">Symbol</div>
                            <div class="canvas_feature__text">
                                {{data.icon}}
                            </div>
                        </div>
                    </div>
                    <div class="canvas_features__col" v-if="data.template">
                        <div class="canvas_feature">
                            <div class="canvas_feature__title">Template</div>
                            <div class="canvas_feature__text">
                                {{data.template}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="article_canvas_back__section">
                <div class="article_canvas_back__title">Contract details</div>
                <div class="canvas_data">
                    <div class="canvas_data__row">
                        <div class="canvas_data__col">
                            Contract Address
                        </div>
                        <div class="canvas_data__col">
                            <a :href="this.polygonUrl + '/address/' + this.contractAddress" target="_blank" class="canvas_primary">{{addressSmall}}</a>
                        </div>
                    </div>
                    <div class="canvas_data__row">
                        <div class="canvas_data__col">
                            Token ID
                        </div>
                        <div class="canvas_data__col">
                            {{contractInfo.nftId}}
                        </div>
                    </div>
                    <div class="canvas_data__row">
                        <div class="canvas_data__col">
                            Token Standard
                        </div>
                        <div class="canvas_data__col">
                            {{contractInfo.standart}}
                        </div>
                    </div>
                    <div class="canvas_data__row">
                        <div class="canvas_data__col">
                            Blockchain
                        </div>
                        <div class="canvas_data__col">
                            {{contractInfo.blockchain}}
                        </div>
                    </div>
                    <div class="canvas_data__row">
                        <div class="canvas_data__col">
                            Metadata
                        </div>
                        <div class="canvas_data__col">
                            {{contractInfo.metadata}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'Back1',
    props: {
        data: Object,
        contractInfo: Object,
        template: String
    },
    data: () => ({
        polygonUrl: null,
        contractAddress: null
    }),
    computed: {
        addressSmall: function() {
            const address = this.contractAddress;
            let reform_address = "";

            reform_address += address.substr(0, 6) + "..." + address.substr(address.length - 4);

            return reform_address;
        }
    },
    mounted(){
        this.polygonUrl = process.env.VUE_APP_POLYGON
        this.contractAddress = process.env.VUE_APP_CONTRACT_ADDRESS
    }
}
</script>


<style lang="scss">
.article_canvas_back {
    display: block;
    backface-visibility: hidden;
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transform: rotateY(180deg) translateZ(0);
    padding: 0;
    font-family: Poppins;
}
.article_canvas_back__image{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotateY(180deg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
}
.article_canvas_back__content {
    padding: 32px;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

.article_canvas_back__section {
    margin-bottom: 31px;
    position: relative;
    &:last-child {
        margin-bottom: 0;
    }
}
.article_canvas_back__title {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #fff;
    margin-bottom: 16px;
}

.canvas_features {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px -8px;
}
.canvas_features__col {
    width: 33.33%;
    padding: 0 5px;
    margin-bottom: 8px;
    .canvas_feature {
        height: 100%;
    }
}
.canvas_feature {
    background: #FDFCF1;
    border: 1.08803px solid #9E2017;
    border-radius: 9px;
    padding: 10px 8px;
    text-align: center;
    min-height: 76px;
}
.canvas_feature__title {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #9E2017;
    margin-bottom: 6px;
}
.canvas_feature__text {
    font-size: 15px;
}
.article_canvas_link {
    color: #9E2017;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: -0.01em;
    display: block;
}
.canvas_primary {
    color: #9E2017;
    text-decoration: none;
}

.canvas_data {
    color: #fff;
    margin-bottom: -10px;
    font-size: 16px;
}
.canvas_data__row {
    margin: 0 -5px 8px;
    display: flex;
    flex-wrap: wrap;
}
.canvas_data__col {
    padding: 0 5px;
    width: 50%;
    &:nth-child(even) {
        text-align: right;
    }
}

@media (max-width: 767px) {
    .article_canvas_back__title {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 8px;
    }
    .canvas_feature {
        padding: 6px;
        min-height: 0;
    }
    .canvas_feature__title {
        line-height: 16px;
    }
    .canvas_feature__text {
        font-size: 14px;
        line-height: 16px;
    }
    .article_canvas_back__section {
        margin-bottom: 18px;
    }
    .article_canvas_link {
        font-size: 12px;
        line-height: 16px;
    }
    .article_canvas_back__content {
        padding: 15px 18px;
    }
    .canvas_data {
        font-size: 12px;
        line-height: 16px;
    }
}

</style>





