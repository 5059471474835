<template>
	<div class="container container--small">
		<div class="justMintedContainer" id="just_minted">
			<div class="justMinted__header">
				<div class="justMinted--title">Just minted</div>
				<a :href="`${openseaUrl}/collection/${collection}`" target="_blank" class="justMinted--link">View on OpenSea<img :src="require('@/assets/images/icons/arrow-right-dark2.svg')"/></a>
			</div>
			<div class="justMinted" v-if="items.length">
				<a class="justMinted__item" v-for="(item, index) in items" :key="index" :href="item.asset.permalink" target="_blank">
					<img :src="item.asset.image_url" alt="">
				</a>
			</div>
			<div class="justMinted skeleton" v-else>
				<div class="justMinted__item" v-for="i in 7" :key="i"></div>
			</div>
		</div>
	</div>
</template>

<script>

import axios from 'axios'
export default {
	name: "JustMinted",
	data: () => ({
		items: [],
		contractAddress: null,
		openseaAssetsUrl: null,
		openseaUrl: null,
		collection: null
	}),
	methods: {
		async getLastMinted(){
			const $this = this;
			const options = {
				headers: {Accept: 'application/json', 'X-API-KEY': process.env.VUE_APP_OPENSEA_API_KEY}
			};

			await axios.get(process.env.VUE_APP_OPENSEA_API_URL + "/events?collection_slug="+ process.env.VUE_APP_OPENSEA_COLLECTION +"&event_type=transfer&only_opensea=false&limit=20", options).then(response => {
				for (var i = 0; i < response.data.asset_events.length; i++) {
					const item = response.data.asset_events[i];

					if(item.from_account.address == "0x0000000000000000000000000000000000000000"){
						$this.items.push(item);
					}

					if($this.items.length >= 7){
						return false;
					}
				}
			})
				console.log($this.items);
		},
	},
	mounted(){
		this.contractAddress = process.env.VUE_APP_CONTRACT_ADDRESS
		this.openseaAssetsUrl = process.env.VUE_APP_OPENSEA_ASSETS_URL
		this.openseaUrl = process.env.VUE_APP_OPENSEA_URL
		this.collection = process.env.VUE_APP_OPENSEA_COLLECTION

		this.getLastMinted();
	}
}
</script>

<style lang="scss">
	.justMintedContainer{
		padding-top: 80px;
	}
	.justMinted {
		display: flex;
		margin-right: -13px;
		align-items: flex-start;
		width: calc(100% + 13px);

		&__header{
			margin-bottom: 35px;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&--title{
			font-family: DM Mono;
			font-size: 32px;
			line-height: 44px;
			color: #253137;
		}

		&--link{
			font-family: Poppins;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			color: #14142B;
			display: inline-flex;
			align-items: center;
			text-decoration: none;
			text-transform: uppercase;
			img {
				height: 16px;
				margin-left: 10px;
			}
		}

		&__item {
			width: calc(14.286% - 13px);
			margin-right: 13px;
			position: relative;
			background: #f9f9f9;
			&:before{
				content: "";
				display: block;
				padding-bottom: 100%;
				width: 100%;
			}

			img{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				transition: all 0.3s ease;
				display: block;
			}
		}

		&.skeleton{
			.justMinted__item{
				position: relative;
				overflow: hidden;
				&:after {
					content: "";
					background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.3), hsla(0, 0%, 100%, 0));
					animation: loading 1.5s infinite;
					position: absolute;
					right: 0;
					left: 0;
					top: 0;
					height: 100%;
					z-index: 1;
					transform: translateX(-100%);
				}
			}
		}
	}

	@keyframes loading {
		to {
			transform: translateX(100%);
		}
	}

	@media (min-width: 992px) {
		.justMinted {
			&__item {
				&:hover{
					img{
						transform: scale(1.03);
					}
				}
			}
		}
	}

	@media (max-width: 991px) {
		.justMintedContainer{
			padding-top: 40px;
		}

		.justMinted{
			overflow-x: scroll;
			width: 100%;
			margin-left: -16px;
			margin-right: -16px;
			width: calc(100% + 32px);
			padding: 0;
			scrollbar-width: none;
			&:after,
			&:before {
				content: "";
				width: 16px;
				height: 1px;
				display: block;
				flex-shrink: 0;
			}
			&::-webkit-scrollbar {
				display: none;
			}
			&__header{
				margin-bottom: 20px;
			}
			&--title{
				font-size: 20px;
				line-height: 28px;
			}
			&--link{
				font-size: 12px;
				line-height: 16px;
				img{
					height: 11px;
					margin-left: 4px;
				}
			}
			&__item{
				width: 157px;
				flex-shrink: 0;
				margin-right: 12px;
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
</style>








