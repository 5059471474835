<template>
	<div>
		<span @click="openSuccess" style="cursor: pointer" class="mintToast--nft">NFTs</span> are in your wallet 🎉
	</div>
</template>


<script>
export default {
	name: 'SuccessMintToast',
	emits: ['openSuccessEmit'],
	methods: {
		openSuccess(){
			this.$emit('close-toast')
			this.$emit("openSuccessEmit")
		}
	}
}
</script>

<style lang="scss">
	.mintToast--nft{
		border-bottom: 1px solid rgba(255, 255, 255, 0.3);
		transition: all 0.3s ease;
		&:hover{
			border-color: #fff;
		}
	}
</style>
