<template>
	<div class="container container--small">
		<div class="discord" id="discord">
			<div class="discord__left">
				<div class="discord--title">
					Launch your Mintmade project
				</div>
				<div class="discord--desc">
					We believe that the digital world should be limited only by the imagination, not by smart contracts. Invent your own NFT collections and launch them on Mintmade.
				</div>
				<a href="https://discord.gg/jChVeh4khp" @click="discordClick" target="_blank" class="discord_button">
					<div class="discord_button__main">
						<div class="discord_button__icon-wrapper">
							<img :src="require('@/assets/images/icons/discord-white.svg')" class="discord_button__icon" alt="">
						</div>
						<span>Join our Discord</span>
					</div>
				</a>
			</div>
			<div class="discord__right">
				<img v-lazy="require('@/assets/images/discord.png')" alt="">
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Discord",
	methods: {
		discordClick(){
			window.amplitude.getInstance().logEvent('click discord')
		},
	}
}
</script>

<style lang="scss">
	.discord{
		padding-top: 150px;
		display: flex;
		position: relative;
		&:after {
			content: "";
			position: absolute;
			top: 150px;
			bottom: 0;
			left: 0;
			right: 0;
			border: 1px dashed #9E2017;
		}
	}
	.discord__left{
		padding: 40px 40px 42px 40px;
		flex: 1;
	}
	.discord__right{
		width: 297px;
		position: relative;
		flex-shrink: 0;
		padding-bottom: 10px;
		padding-right: 10px;
		z-index: 1;
	}
	.discord__right img{
		width: 100%;
		position: absolute;
		bottom: 10px;
		right: 10px;
	}
	.discord_button {
		border: 0;
		flex-grow: 1;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		background-color: #9E2017;
		height: 52px;
		padding: 0 20px;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: #fff;
		font-family: Poppins;
		white-space: nowrap;
		cursor: pointer;
		overflow: hidden;
		transition: background 0.3s, box-shadow 0.3s;
		text-decoration: none;
		text-transform: uppercase;
		&:hover {
			box-shadow: 0px 0px 10px rgba(0,0,0, 0.2);
		}
	}
	.discord_button__main {
		display: flex;
		align-items: center;
		& > * {
			z-index: 1;
		}
	}
	.discord_button__icon-wrapper {
		position: relative;
		margin-right: 12px;
		flex-shrink: 0;
	}
	.discord_button__icon {
		max-width: 24px;
		height: auto;
		display: block;
		z-index: 1;
		position: relative;
	}
	.discord--title{
		font-family: DM Mono;
		font-weight: 500;
		font-size: 24px;
		line-height: 32px;
		color: #253137;
		margin-bottom: 12px;
	}
	.discord--desc{
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 32px;
		color: #253137;
		span{
			font-style: italic;
			font-weight: 300;
			text-decoration: none;
		}
	}

	@media (max-width: 991px) {
		.discord{
			padding-top: 40px;
			&:after {
				top: 40px;
			}
		}
		.discord__left{
			padding: 20px;
		}
		.discord__right{
			display: none;
		}
		.discord--title{
			font-size: 20px;
			line-height: 28px;
		}
		.discord--desc{
			font-size: 14px;
			line-height: 20px;
			margin-bottom: 20px;
		}
		.discord_button{
			width: 100%;
		}
	}
</style>
