<template>
	<div class="about" id="about">
		<div class="container container--small">
			<div class="about_content">
				<div class="about_content__row">
					<div class="about_content__col">
						<div class="about_title">Celebrating<br> <span>the freedom of love</span></div>

						<!-- Mobile -->
						<div class="about_content__mobile about_content__mob_image_list">
							<div class="about_image_list">
								<div class="about_image_list__item about_image">
									<div class="about_image__inner">
										<div class="about_image__item" v-for="(image, index) in aboutImages" v-bind:key="index">
											<img :src="require('@/assets/images/'+image)" alt="">
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- Mobile END -->

						<div class="about_text">
							<p class="about_text_p">Sometimes, it takes courage to share your feelings. We want this collection to provide an avenue for people to declare their feelings publicly on the blockchain.</p>
							<div class="about_text_title">About Mintmade</div>
							<p class="about_text_p"><a href="https://mintmade.io/" target="_blank">Mintmade</a> is a new NFT platform that steps away from the speculative usage of NFTs and utilizes Web3 infrastructure to become the printing press of the Metaverse era. We are supported by <a href="https://cointelegraph.com/" target="_blank">Cointelegraph</a> – the largest media outlet in blockchain and crypto.</p>
						</div>
					</div>
					<div class="about_content__col">
						<!-- Desktop -->
						<div class="about_image_list about_content__desktop">
							<div class="about_image_list__item about_image">
								<div class="about_image__inner">
									<div class="about_image__item" v-for="(image, index) in aboutImages" v-bind:key="index">
										<img :src="require('@/assets/images/'+image)" alt="">
									</div>
								</div>
							</div>
						</div>
						<!-- Desktop END -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		aboutImages: [
			'about-image-1.jpg',
			'about-image-2.jpg',
			'about-image-3.jpg',
			'about-image-4.jpg',
			'about-image-5.jpg',
			'about-image-6.jpg',
			'about-image-7.jpg',
			'about-image-8.jpg',
			'about-image-9.jpg'
		]
	}),
	methods: {
		fetchAboutImages: function() {
			let newAboutImages = [];
			for(let g = 0; g < 2; g++){
				for (let i = 0; i < this.aboutImages.length; i+=3) {
					for(let v = 0; v < 3; v++) {
						newAboutImages.push(this.aboutImages.slice(i, i+3));
					}
				}
			}
			this.aboutImages = newAboutImages.flat();
		}
	},
	created: function() {
		this.fetchAboutImages();
	}
}
</script>


<style lang="scss">
.about {
	padding-top: 80px;
}
.about_content__row {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -16px;
}
.about_content__col {
	width: 50%;
	padding: 0 16px;
}
.about_title {
	font-family: DM Mono;
	font-size: 64px;
	line-height: 55px;
	color: #253137;
	margin-bottom: 13px;
	span{
		font-size: 40px;
	}
}
.about_text_p {
	margin: 12px 0;
	font-size: 16px;
	line-height: 24px;
	color: #253137;
	&:last-child {
		margin-bottom: 0;
	}
	a{
		color: #253137;
	}
}
.about_text_link {
	color: #9E2017;
	text-decoration: none;
	&:hover {
		color: inherit;
	}
}
.about_text_title {
	font-weight: bold;
	font-size: 20px;
	line-height: 28px;
	margin: 48px 0 0 0;
}
.about_image_list {
	display: flex;
	align-items: stretch;
	height: 100%;
	flex-direction: column;
}
.about_image_list__item {
	margin-bottom: 12px;
	&:last-child {
		margin-bottom: 0;
	}
}
.about_image {
	width: 100%;
	flex-grow: 1;
	position: relative;
	background: #1a1b21;
	margin-bottom: 16px;
	overflow: hidden;
	border-radius: 5px
}
.about_image__inner {
	position: absolute;
	display: grid;
	grid-template-columns: repeat(9, 1fr);
	width: 400%;
	background: #1a1b21;
	gap: 3px;
	top: 0;
	transform: rotate(30deg) translate3d(-13.3%, -20%, 0);
	transform-origin: left top;
	will-change: transform;
	animation: about-image-marquee 30s infinite linear;
	@keyframes about-image-marquee {
		from {
			transform: rotate(30deg) translate3d(-13.3%, -20%, 0);
		}
		to {
			transform: rotate(30deg) translate3d(-46.6%, -20%, 0);
		}
	}
}
.about_image__item {
	padding-top: 100%;
	width: 100%;
	position: relative;
}
.about_image__item img {
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.about_content__mobile {
	display: none;
}
@media (max-width: 991px) {
	.about {
		padding-top: 40px;
	}
	.about_content__col {
		width: 100%;
	}
	.about_title {
		font-size: 20px;
		line-height: 28px;
		span{
			font-size: 20px;
		}
	}
	.about_content__desktop {
		display: none;
	}
	.about_content__mobile {
		display: block;
	}
	.about_image {
		height: 262px;
		margin-bottom: 12px;
	}
	.about_content__mob_image_list {
		margin-bottom: 20px;
	}
	.about_text_p {
		font-size: 16px;
		line-height: 24px;
	}
	.about_text_title {
		font-size: 16px;
		line-height: 24px;
		margin-top: 20px;
	}
}
</style>
