// @ts-nocheck
const SUPPORTED_MIMES = {
  jpeg: "image/jpeg",
  jpg: "image/jpeg",
  png: "image/png"
};

const S3 = require('aws-sdk/clients/s3');
const s3Client = new S3({
	"accessKeyId":process.env.VUE_APP_ACCESS_KEY_ID,
	"secretAccessKey":process.env.VUE_APP_SECRET_ACCESS_KEY,
	"region":process.env.VUE_APP_REGION
});


export async function uploadImage(nftId, base64Data, width, height, premium = false){
	const reformBase64Data = new Buffer.from(base64Data.replace(/^data:image\/\w+;base64,/, ""), 'base64');

	let name = nftId + ".jpeg";
	if(premium){
		name = nftId + "-premium.jpeg";
	}

	const dataImage = {
		Key: name, 
		Body: reformBase64Data,
		ContentEncoding: 'base64',
		ContentType: 'image/jpeg',
		Bucket: 'mintmade-two-nft-images'
	};

	console.log(dataImage)
	
	try{
		const result = await s3Client.putObject(dataImage).promise();
		createSmallImage(nftId, base64Data, width, height, premium);
		return result
	} catch{
		return null
	}
}

async function createSmallImage(nftId, base64Data, width, height, premium = false){
	const scale = 972/width

	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');
	canvas.width = width * scale;
	canvas.height = height * scale;

	const image = new Image();
	image.onload = function() {
		setTimeout(function(){
			ctx.drawImage(image, 0, 0, width * scale, height * scale);
			uploadSmallImage(nftId, canvas, premium)
		}, 1000);
	};
	image.src = base64Data;

	const scale2 = 16/width
	const canvas2 = document.createElement('canvas');
	const ctx2 = canvas2.getContext('2d');
	canvas2.width = width * scale2;
	canvas2.height = height * scale2;

	const image2 = new Image();
	image2.onload = function() {
		setTimeout(function(){
			ctx2.drawImage(image2, 0, 0, width * scale2, height * scale2);
			uploadLqipImage(nftId, canvas2, premium)
		}, 1000);
	};
	image2.src = base64Data;
}

async function uploadSmallImage(nftId, canvas, premium = false){
	const reformBase64Data = new Buffer.from(canvas.toDataURL("image/jpeg").replace(/^data:image\/\w+;base64,/, ""), 'base64');

	let name = nftId + "_972.jpeg";
	if(premium){
		name = nftId + "_972-premium.jpeg";
	}

	// console.log(premium);

	const dataImage = {
		Key: name, 
		Body: reformBase64Data,
		ContentEncoding: 'base64',
		ContentType: 'image/jpeg',
		Bucket: 'mintmade-two-nft-images'
	};

	await s3Client.putObject(dataImage).promise();
}

async function uploadLqipImage(nftId, canvas, premium = false){
	const reformBase64Data = new Buffer.from(canvas.toDataURL("image/jpeg").replace(/^data:image\/\w+;base64,/, ""), 'base64');

	let name = nftId + "_lqip.jpeg";
	if(premium){
		name = nftId + "_lqip-premium.jpeg";
	}

	const dataImage = {
		Key: name, 
		Body: reformBase64Data,
		ContentEncoding: 'base64',
		ContentType: 'image/jpeg',
		Bucket: 'mintmade-two-nft-images'
	};

	await s3Client.putObject(dataImage).promise();
}

export async function getImage(nftId, premium = false){
	let key = nftId + "_972.jpeg";

	if(premium){
		key = nftId + "_972-premium.jpeg";
	}

	try{
		return await s3Client.getObject({
			Bucket: 'mintmade-two-nft-images',
			Key: key
		}).promise();
	} catch{
		return null
	}	
}

export async function getLqipImage(nftId, premium = false){
	let key = nftId + "_lqip.jpeg";

	if(premium){
		key = nftId + "_lqip-premium.jpeg";
	}

	try{
		return await s3Client.getObject({
			Bucket: 'mintmade-two-nft-images',
			Key: key
		}).promise();
	} catch{
		return null
	}	
}