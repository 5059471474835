<template>
	<div>
		<div class="page_content" v-if="detailPage">
			<div
				class="container container--small"
			>
				<div class="product_content">
					<div class="product_content__row" :class="{minted: contractInfo.nftId && nftModel && nftModel.nftData && nftModel.nftData.totalSupply && nftModel.nftData.totalSupply >= nftModel.nftData.maxSupply}">
						<div class="product_content__col">
							<div class="canvas_image__wrapper" :class="{'rendered': isRendered}">
								<div class="canvas_image__preloaderContainer" v-if="!isRendered">
									<img :src="require('@/assets/images/icons/preloader.svg')" alt="" class="canvas_image__preloader">
								</div>

								<canvas-image 
									v-if="Object.keys(data).length !== 0"
									:data="data"
									:contractInfo="contractInfo"
									@onSetImageBase64="setImageBase64"
									@onSetIsRendered="setIsRendered"
									:style="[!isRendered ? {'display': 'none'} : {'display': 'block'}]"
								/>
								
							</div>
						</div>
						<div class="product_content__col">
							<div class="product_content__desktop">
								<div class="product_info_minted" v-if="contractInfo.nftId && nftModel && nftModel.nftData && nftModel.nftData.totalSupply && nftModel.nftData.totalSupply >= nftModel.nftData.maxSupply">
									<div class="product_info_minted--title">Love at first mint</div>
									<div class="product_info_minted--desc">{{data.name1}} issues this token in honor of {{data.name2}} as a symbol of everlasting partnership, friendship and respect. This has been forever imprinted on the Polygon blockchain.</div>
									<div class="share">
										<div class="share__title">Share</div>
										<div class="share_list">
											<a target="_blank" @click="shareClick('twitter')" :href="`https://twitter.com/intent/tweet?url=https://share.mintmade.io/two/${data.id}&text=I%E2%80%99ve%20just%20minted%20a%20%23ValentinesDay%20card%20%23NFT!%20Declare%20your%20feelings%20on%20the%20%23blockchain%20with%20%40mintmadeclub`" class="share_item"><img :src="require('@/assets/images/icons/twitter-grey.svg')" alt=""></a>
											<a target="_blank" @click="shareClick('facebook')" :href="`https://www.facebook.com/sharer/sharer.php?u=https://share.mintmade.io/two/${data.id}`" class="share_item"><img :src="require('@/assets/images/icons/facebook-grey.svg')" alt=""></a>
											<a target="_blank" @click="shareClick('telegram')" :href="`https://t.me/share/url?url=https://share.mintmade.io/two/${data.id}`" class="share_item"><img :src="require('@/assets/images/icons/telegram-grey.svg')" alt=""></a>
											<div class="share_item" @click="copyShareLink"><img :src="require('@/assets/images/icons/link-grey.svg')" alt=""></div>
										</div>
									</div>
									<router-link
										to="/"
										class="block_link payment_success__button">
										<img :src="require('@/assets/images/icons/gift.svg')" class="block_link__icon" alt="">
										<span>Create your Valentine’s NFT</span>
										<img :src="require('@/assets/images/icons/arrow-right-dark.svg')" alt="" class="block_link__arrow">
									</router-link>
									<div class="product_info_minted--desc2">It takes a mintue before NFT becomes available in your <a :href="openseaUrl + '/account'" target="_blank">OpenSea account</a>.</div>
								</div>
								<div class="product_info_list" v-else>
									<div class="product_info_list__item product_info">
										<div class="product_info__block">
											<div class="product_info__label">You pay</div>
											<div class="product_info__cost" v-if="cost && maticRates">
												<span class="product_info__cost_main">
													<img :src="require('@/assets/images/icons/coins/matic.svg')" alt="matic"> 
													{{cost}}
												</span>
												<span class="product_info__cost_currency">≈ ${{((maticRates.USD * cost).toFixed(2))}}</span>
											</div>
											<div class="product_info__cost" v-else>
												<span class="product_info__cost_main skeleton"></span>
												<span class="product_info__cost_currency skeleton"></span>
											</div>
										</div>
										<div class="product_info__block">
											<div class="product_info__label">You get</div>
											<div class="product_info__title" v-if="Object.keys(data).length !== 0">“{{data.name1}} <img :src="require('@/assets/images/input_icons/' + data.icon + '.svg')" alt=""> {{data.name2}}”&nbsp;<span>x2</span></div>
											<div class="product_info__title skeleton" v-else>
												<div class="skeleton_p" />
											</div>
											<div class="product_info__note">2 unique NFTs (for you and your partner) to declare your feelings on Polygon blockchain</div>
										</div>
									</div>
									<div class="product_info_list__item product_info">
										<div class="product_info__mint">
											<div class="mint_button" :class="{disable: !contractInfo.nftId || !nftModel.hasImageS3 || cost === null}" @click="buyNft()">
												<div class="mint_button__main">
													<div class="mint_button__icon-wrapper">
														<img
															:src="require('@/assets/images/icons/cart-white.svg')"
															class="mint_button__icon"
															alt=""
														>
													</div>
													<span>Mint this NFT</span>
												</div>
												<div class="mint_button__extra">{{cost}} MATIC</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="product_content__mobile">
								<div class="product_info_minted" v-if="contractInfo.nftId && nftModel && nftModel.nftData && nftModel.nftData.totalSupply && nftModel.nftData.totalSupply >= nftModel.nftData.maxSupply">
									<div class="product_info_minted--title">Love at first mint</div>
									<div class="product_info_minted--desc">{{data.name1}} issues this token in honor of {{data.name2}} as a symbol of everlasting partnership, friendship and respect. This has been forever imprinted on the Polygon blockchain.</div>
									<div class="share">
										<div class="share__title">Share</div>
										<div class="share_list">
											<a target="_blank" @click="shareClick('twitter')" :href="`https://twitter.com/intent/tweet?url=https://share.mintmade.io/two/${data.id}&text=I%E2%80%99ve%20just%20minted%20a%20%23ValentinesDay%20card%20%23NFT!%20Declare%20your%20feelings%20on%20the%20%23blockchain%20with%20%40mintmadeclub`" class="share_item"><img :src="require('@/assets/images/icons/twitter-grey.svg')" alt=""></a>
											<a target="_blank" @click="shareClick('facebook')" :href="`https://www.facebook.com/sharer/sharer.php?u=https://share.mintmade.io/two/${data.id}`" class="share_item"><img :src="require('@/assets/images/icons/facebook-grey.svg')" alt=""></a>
											<a target="_blank" @click="shareClick('telegram')" :href="`https://t.me/share/url?url=https://share.mintmade.io/two/${data.id}`" class="share_item"><img :src="require('@/assets/images/icons/telegram-grey.svg')" alt=""></a>
											<div class="share_item" @click="copyShareLink"><img :src="require('@/assets/images/icons/link-grey.svg')" alt=""></div>
										</div>
									</div>
									<router-link
										to="/"
										class="block_link payment_success__button">
										<img :src="require('@/assets/images/icons/gift.svg')" class="block_link__icon" alt="">
										<span>Create your Valentine’s NFT</span>
										<img :src="require('@/assets/images/icons/arrow-right-dark.svg')" alt="" class="block_link__arrow">
									</router-link>
									<div class="product_info_minted--desc2">It takes a mintue before NFT becomes available in your <a :href="openseaUrl + '/account'" target="_blank">OpenSea account</a>.</div>
								</div>
								<div v-else>
									<div class="product_info__label">You pay</div>
									<div class="product_info_list">
										<div class="product_info product_info_list__item">
											<div class="product_info__mob_count_row">
												<div class="product_info__cost" v-if="cost && maticRates">
													<span class="product_info__cost_main">
														<img :src="require('@/assets/images/icons/coins/matic.svg')" alt="matic"> 
														{{cost}}
													</span>
													<span class="product_info__cost_currency">≈ ${{((maticRates.USD * cost).toFixed(2))}}</span>
												</div>
												<div class="product_info__cost" v-else>
													<span class="product_info__cost_main skeleton"></span>
													<span class="product_info__cost_currency skeleton"></span>
												</div>
											</div>
											<div class="product_info__mint">
												<div class="mint_button" :class="{disable: !contractInfo.nftId || !nftModel.hasImageS3 || cost === null}" @click="buyNft()">
													<div class="mint_button__main">
														<div class="mint_button__icon-wrapper">
															<img
																:src="require('@/assets/images/icons/cart-white.svg')"
																class="mint_button__icon"
																alt=""
															>
														</div>
														<span>Mint this NFT</span>
													</div>
													<div class="mint_button__extra">{{cost}} MATIC</div>
												</div>
											</div>
										</div>
										<div class="product_info_list__item product_info">
											<div class="product_info__block">
												<div class="product_info__label">You get</div>
												<div class="product_info__title" v-if="Object.keys(data).length !== 0">“{{data.name1}} <img :src="require('@/assets/images/input_icons/' + data.icon + '.svg')" alt=""> {{data.name2}}”&nbsp;<span>x2</span></div>
											</div>
											<div class="product_info__block product_info__block--note">
												<div class="product_info__note">2 unique NFTs (for you and your partner) to declare your feelings on Polygon blockchain</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<input-article v-if="!detailPage" @onSetDetailPage="setDetailPage"/>

		<JustMinted />

		<PoweredBy />

		<About :aboutContent="aboutContent"/>

		<Discord />

		<Faq
			:faqContent="faqContent"
		/>

		<!-- Payment method popup -->
		<payment-method
			:isActive="isPaymentMethodActive"
			@onPaymentMethodChoose="handlePaymentMethodChoose"
			@onClose="closePaymentMethod"
		/>
		<payment-card
			:isActive="isPaymentCardActive"
			:nftModel="nftModel"
			@onClose="closePaymentCard"
			@onSuccess="openSuccess"
			@onSetTransaction="setTransaction"
		/>
		<!-- Payment method popup END -->

		<!-- Payment process popup -->
		<payment-process
			@onClose="closePaymentProcess"
			:isActive="isPaymentProcessActive"
			:activeStep="payProccessStep"
			:stepError="payProccessError"
			:tokenId="contractInfo.nftId"
			:preview="{
				image: payProccessImage,
				name1: data.name1,
				name2: data.name2,
				icon: data.icon,
				cost: `${cost} MATIC`,
				costInCurrency: `${(maticRates ? maticRates.USD * cost : 0).toFixed(2)}$`
			}"
		/>
		<!-- Payment process popup END -->

		<!-- Payment success popup -->
		<payment-success
			:tokenId="contractInfo.nftId"
			:image="payProccessImage"
			:shareLink="`https://share.mintmade.io/two/${data.id}`"
			:isActive="isPaymentSuccessActive"
			:quantity="1"
			:transaction="transaction"
			:price="maticRates ?  (maticRates.USD * cost).toFixed(2) : '0'"
			@onClose="closePaymentSuccess"
		/>
		<!-- Payment success popup END -->
	</div>

</template>

<script>
import '@/assets/css/product.scss';
import { getNftData } from "@/helpers/api.ts"
import { Nft } from "@/helpers/nft.ts"
import aboutContent from "@/assets/json/productPage/about.json";
import faqContent from "@/assets/json/productPage/faq.json";
import Faq from "@/components/Faq.vue";
import About from "@/components/About.vue";
import Discord from "@/components/Discord.vue";
import PoweredBy from "@/components/PoweredBy/PoweredBy.vue";
import InputArticle from "@/components/InputArticle/InputArticle.vue";
import JustMinted from "@/components/JustMinted/JustMinted.vue";
import { useMeta } from 'vue-meta'
import { useToast } from "vue-toastification";
import SuccessMintToast from "@/components/SuccessMintToast.vue";
import Mint50BlocksToast from "@/components/Mint50BlocksToast.vue";
import router from "@/router";
import getCoinsRate from "@/helpers/getCoinsRate.ts"
import CanvasImage from '@/components/CanvasImage/CanvasImage.vue';
import PaymentProcess from "@/components/PaymentProcess/PaymentProcess.vue";
import PaymentMethod from "@/components/PaymentMethod/PaymentMethod.vue";
import PaymentCard from "@/components/PaymentCard/PaymentCard.vue";
import PaymentSuccess from "@/components/PaymentSuccess/PaymentSuccess.vue";
import contractInfo from "@/assets/json/contract_info.json";
import copyToClipboard from "@/helpers/copyToClipboard.ts"

export default {
	name: "Product",
	setup () {
		useMeta({ title: 'Declare your feelings on the blockchain' })
	},
	emits: ['buyNft', 'closePaymentMethod', 'buyWithNft'],
	props: {
		isPaymentMethodActive: Boolean
	},
	data: () => ({
		pageTitle: "Declare your feelings on the blockchain",
		openGraphTitle: null,
		openGraphDescription: null,
		aboutContent,
		faqContent,
		openseaAssetsUrl: null,
		contractAddress: "",
		detailPage: false,
		data: {},
		maticRates: null,
		isPaymentProcessActive: false,
		isPaymentCardActive: false,
		isPaymentSuccessActive: false,
		selectedPaymentMethod: null,
		nftModel: null,
		payProccess: false,
		payProccessStep: 1,
		payProccessError: null,
		payProccessImage: "",
		contractInfo,
		cost: null,
		transaction: "",
		polygonUrl: null
	}),
	computed: {
	},
	components: { 
		Faq,
		About,
		InputArticle,
		Discord,
		JustMinted,
		PoweredBy,
		CanvasImage,
		PaymentProcess,
		PaymentMethod,
		PaymentCard,
		PaymentSuccess
	},
	watch: {
		'$route.params.nftId'(){
			if(this.$route.params.nftId){
				this.detailPage = true
				this.getNftData();
			} else{
				this.detailPage = false;
			}
		},
		detailPage(){
			if(this.detailPage){
				this.nftModel = new Nft();
				this.data = {};
				this.$store.state.wallet.currentNftId = null;
				this.contractInfo.nftId = null;
				this.cost = null;
				this.isRendered = false;
			}
		},
		pageTitle() {
			document.title = this.pageTitle;
		},
		openGraphTitle() {
			if(this.openGraphTitle){
				if(document.querySelector("[property='og:title']")){
					document.querySelector("[property='og:title']").content = this.openGraphTitle
				} else{
					const meta = document.createElement('meta');
					meta.setAttribute("property", "og:title");
					meta.content = this.openGraphTitle;
					document.getElementsByTagName('head')[0].appendChild(meta);
				}
			} else{
				document.querySelector("[property='og:title']").content = ""
			}
		},
		openGraphDescription() {
			if(this.openGraphDescription){
				if(document.querySelector("[property='og:description']")){
					document.querySelector("[property='og:description']").content = this.openGraphDescription
				} else{
					const meta = document.createElement('meta');
					meta.setAttribute("property", "og:description");
					meta.content = this.openGraphDescription;
					document.getElementsByTagName('head')[0].appendChild(meta);
				}
			} else{
				document.querySelector("[property='og:description']").content = ""
			}
		},
		closePaymentProcess() {
			this.isPaymentProcessActive = false;
		}
	},
	methods: {
		shareClick(soc){
			const props = {}
			props[soc] = true

			window.amplitude.getInstance().logEvent('click share button', props)
		},
		copyShareLink() {
			const toast = useToast();
			toast.success("URL has been copied to clipboard", { timeout: 3000 });
			window.amplitude.getInstance().logEvent('click share button', {'link': true})
			copyToClipboard(`https://share.mintmade.io/two/${this.data.id}`);
		},
		setDetailPage(detailPage){
			this.detailPage = detailPage
		},
		setTransaction(transaction){
			this.transaction = transaction
		},
		setImageBase64(canvas){
			this.payProccessImage = canvas;
			this.nftModel.hasImageS3 = true;
		},
		setIsRendered(isRendered){
			this.isRendered = isRendered
		},
		buyNft(){
			this.$emit('buyNft');
		},
		async getNftData(){
			const $this = this
			if(this.$route.params.nftId){
				this.detailPage = true
				const data = await getNftData(this.$route.params.nftId)
				this.data["id"] = this.$route.params.nftId

				if(data != null){
					let partnerIterator = 0;
					data.attributes.forEach(function(item){
						if(item.trait_type == "Collection"){
							$this.data["collection"] = item.value;
						}
						if(item.trait_type == "Partner" && partnerIterator == 0){
							$this.data["name1"] = item.value;
							partnerIterator++;
						}
						if(item.trait_type == "Partner" && partnerIterator == 1){
							$this.data["name2"] = item.value;
						}
						if(item.trait_type == "Symbol"){
							$this.data["icon"] = item.value;
						}
						if(item.trait_type == "Template"){
							$this.data["template"] = item.value;
						}
					})

					const nftId = parseInt(this.$route.params.nftId)
					this.$store.state.wallet.currentNftId = parseInt(nftId)
					this.contractInfo.nftId = parseInt(nftId)

					this.$store.state.wallet.premium = $this.data["template"] == "Pixel Art Premium"
					this.nftModel.premium = $this.data["template"] == "Pixel Art Premium"
					
					await this.nftModel.updateNftId(nftId, $this.data["template"] == "Pixel Art Premium")
					this.cost = this.nftModel.nftData.price
				} else{
					router.push({path: "/"})
				}
			} else{
				this.detailPage = false
			}
		},
		closePaymentProcess() {
			this.isPaymentProcessActive = false;
		},
		closePaymentMethod() {
			this.$emit('closePaymentMethod');
		},
		closePaymentCard() {
			this.isPaymentCardActive = false;
		},
		closePaymentSuccess() {
			this.isPaymentSuccessActive = false;
		},
		handlePaymentMethodChoose({ paymentMethod }) {
			this.selectedPaymentMethod = paymentMethod;
			this.$emit('closePaymentMethod');

			if(paymentMethod == "matic"){
				this.$emit("buyWithNft");
			} else{
				this.isPaymentCardActive = true;
			}
		},
		openSuccess() {
			this.isPaymentSuccessActive = true;
		}
	},
	mounted(){

		const $this = this;
		this.nftModel = new Nft();

		window.amplitude.getInstance().logEvent('NFT page loaded')

		this.getNftData();

		this.openseaUrl = process.env.VUE_APP_OPENSEA_URL
		this.openseaAssetsUrl = process.env.VUE_APP_OPENSEA_ASSETS_URL
		this.contractAddress = process.env.VUE_APP_CONTRACT_ADDRESS
		this.polygonUrl = process.env.VUE_APP_POLYGON

		const wallet = this.$store.state.wallet;
		wallet.openPayPopup = function(){
			$this.payProccessStep = 1;
			$this.isPaymentProcessActive = true;

			if(wallet.chainId == process.env.VUE_APP_RPC){
				window.amplitude.getInstance().logEvent('approve transaction')
			}
		}
		wallet.closePayPopup = function(){
			$this.payProccessStep = 1;
			$this.isPaymentProcessActive = false;
		}
		wallet.onSetTransaction = function(transaction){
			$this.setTransaction(transaction)
		}
		wallet.setStepPayPopup = function(step){
			if(step == 2){
				window.amplitude.getInstance().logEvent('pending transaction')
			}

			if(step == 3){
				if($this.isPaymentProcessActive){
					$this.isPaymentSuccessActive = true;
				} else{
					const toast = useToast();
					const content = {
						component: SuccessMintToast,
						listeners: {
							openSuccessEmit: function(){
								$this.openSuccess()
							}
						}
					};
					toast.success(content);
				}
				$this.isPaymentProcessActive = false;
				$this.nftModel.getNftData();
			} else{
				$this.payProccessStep = step;
			}
		}
		wallet.chainErrorPayPopup = function(){
			if($this.isPaymentProcessActive){
				window.amplitude.getInstance().logEvent('wrong network')
				$this.payProccessError = {
					step: 1,
					error: 'Please, switch your wallet’s RPC to the Polygon network. <a href="https://newaccount1640247483109.freshdesk.com/support/solutions/articles/101000360608-how-can-i-switch-my-wallet-to-polygon-" target="_blank">How to switch a wallet to Polygon?</a>'
				}
			}
		}
		wallet.removeChainErrorPayPopup = function(){
			$this.payProccessError = null
			if(wallet.payPopupOpened){
				wallet.sendMintTransaction();
				window.amplitude.getInstance().logEvent('approve transaction')
			}

		}
		wallet.showError50Blocks = function(){
			let message = `Your NFT was not minted within 50 blocks, please <a class='mintToast--link' target='_blank' href='${this.polygonUrl}/address/${this.contractAddress}'>check your transaction</a> manually`;
			if(this.transaction){
				message = `Your NFT was not minted within 50 blocks, please <a class='mintToast--link' target='_blank' href='${this.polygonUrl}/tx/${this.transaction}'>check your transaction</a> manually`;
			}
			const toast = useToast();
			const content = {
				component: Mint50BlocksToast,
				props: {
					message: message
				}
			};
			toast.error(content);
		}
	},
	async created() {
		getCoinsRate("MATIC").then(r => this.maticRates = r.rates);
	},
	beforeUnmount() { 
		document.querySelector("[property='og:title']").content = ""
		document.querySelector("[property='og:description']").content = ""
	},
}
</script>
