<template>
    <popup-layout
        pupupContentClass="payment_success_popup"
        :isActive="isActive"
        @click="handleClose"
        @onClose="handleClose"
        ref="popup"
    >
        <div class="payment_success">
            <div class="payment_success__header">
                <div class="payment_success--title">Your NFTs are in your wallet 🎉</div>
                <img
                    class="payment_success__close"
                    :src="require('@/assets/images/icons/cross-dark.svg')"
                    @click="handleClose"
                >
            </div>
            <div class="payment_success__body">
                <div class="payment_success__imgContainer" data-canvas-tilt>
                    <img :src="image" class="payment_success__img">
                </div>
                <div class="payment_success__main">
                    <div class="payment_success__text">
                        Congratulations! The tokens were <a :href="polygonUrl + '/tx/' + transaction" target="_blank">successfully minted</a>. Now share one with your partner! Transfer it on OpenSea or wherever your NFTs are stored. 
                    </div>
                    <a :href="`${openseaAssetsUrl}/${contractAddress}/${tokenId}`"
                        @click="sellClick"
                        target="_blank"
                        class="block_link payment_success__button"
                        :class="{'disabled': sellButtonTimer}">
                        <img :src="require('@/assets/images/icons/gift.svg')" class="block_link__icon" alt="">
                        <span>Transfer 2nd token</span>
                        <span
                            v-if="sellButtonTimer"
                        >
                            ({{sellButtonTimer}}s)
                        </span>
                        <img :src="require('@/assets/images/icons/arrow-right-dark.svg')" alt="" class="block_link__arrow">
                    </a>

                    <div class="payment_success__extra_text">It takes a minute before the NFTs become available in your <a :href="openseaUrl + '/account'" target="_blank">OpenSea account</a>.</div>

                    <div class="share">
                        <div class="share__title">Share</div>
                        <div class="share_list">
                            <a target="_blank" @click="shareClick('twitter')" :href="`https://twitter.com/intent/tweet?url=${shareLink}&text=I%E2%80%99ve%20just%20minted%20a%20%23ValentinesDay%20card%20%23NFT!%20Declare%20your%20feelings%20on%20the%20%23blockchain%20with%20%40mintmadeclub`" class="share_item"><img :src="require('@/assets/images/icons/twitter-grey.svg')" alt=""></a>
                            <a target="_blank" @click="shareClick('facebook')" :href="`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`" class="share_item"><img :src="require('@/assets/images/icons/facebook-grey.svg')" alt=""></a>
                            <a target="_blank" @click="shareClick('telegram')" :href="`https://t.me/share/url?url=${shareLink}`" class="share_item"><img :src="require('@/assets/images/icons/telegram-grey.svg')" alt=""></a>
                            <div class="share_item" @click="copyShareLink"><img :src="require('@/assets/images/icons/link-grey.svg')" alt=""></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </popup-layout>
    <!-- Confetti -->
    <div class="confetti-wrap" ref="confettiWrap"></div>
</template>

<script>
import PopupLayout from "@/components/PopupLayout/PopupLayout";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import copyToClipboard from "@/helpers/copyToClipboard.ts"
import amplitude from 'amplitude-js';
import UniversalTilt from 'universal-tilt.js';
import { useToast } from "vue-toastification";

export default {
    name: "PaymentSuccess",
    data: () => ({
        sellButtonTimer: 1,
        sellButtonTimerInterval: null,
        wallet: null,
        contractAddress: null,
        openseaUrl: null,
        openseaAssetsUrl: null,
        polygonUrl: null
    }),
    props: {
        isActive: Boolean,
        image: String,
        shareLink: String,
        tokenId: Number,
        price: String,
        quantity: Number,
        transaction: String
    },
    emits: ['onClose'],
    components: { PopupLayout },
    computed: {
        transactionSmall: function() {
            const transaction = this.transaction;
            let reform_transaction = "";

            reform_transaction += transaction.substr(0, 6) + "..." + transaction.substr(transaction.length - 4);

            return reform_transaction;
        }
    },
    methods: {
        sellClick(){
            window.amplitude.getInstance().logEvent('click sell button')
        },
        handleClose() {
            enableBodyScroll(this.$refs.popup);
            this.$emit('onClose');
        },
        shareClick(soc){
            const props = {}
            props[soc] = true

            window.amplitude.getInstance().logEvent('click share button', props)
        },
        copyShareLink() {
            const toast = useToast();
            toast.success("URL has been copied to clipboard", { timeout: 3000 });
            window.amplitude.getInstance().logEvent('click share button', {'link': true})
            // window.open(document.location.href, "_blank");
            copyToClipboard(`https://share.mintmade.io/two/${this.tokenId}`);
        },
        setSellButtonTimer(seconds) {
                clearInterval(this.sellButtonTimerInterval);
                this.sellButtonTimer = seconds;
                this.sellButtonTimerInterval = setInterval(() => {
                    this.sellButtonTimer -= 1;
                    if(this.sellButtonTimer <= 0) clearInterval(this.sellButtonTimerInterval); 
                }, 1000);
        },
        startConfetti() {
            // confetti
            const Confettiful = function(el) {
                this.el = el;
                this.containerEl = null;

                this.confettiFrequency = 3;
                this.confettiColors = ['#EF2964', '#00C09D', '#2D87B0', '#48485E', '#EFFF1D'];
                this.confettiAnimations = ['slow', 'medium', 'fast'];

                this._setupElements();
                this._renderConfetti();
            };

            Confettiful.prototype._setupElements = function() {
                const containerEl = document.createElement('div');

                containerEl.classList.add('confetti-container');

                this.el.appendChild(containerEl);

                this.containerEl = containerEl;
            };

            Confettiful.prototype._renderConfetti = function() {
                this.confettiInterval = setInterval(() => {
                    const confettiEl = document.createElement('div');
                    const confettiSize = (Math.floor(Math.random() * 3) + 7) + 'px';
                    const confettiBackground = this.confettiColors[Math.floor(Math.random() * this.confettiColors.length)];
                    const confettiLeft = (Math.floor(Math.random() * this.el.offsetWidth)) + 'px';
                    const confettiAnimation = this.confettiAnimations[Math.floor(Math.random() * this.confettiAnimations.length)];

                    confettiEl.classList.add('confetti', 'confetti--animation-' + confettiAnimation);
                    confettiEl.style.left = confettiLeft;
                    confettiEl.style.width = confettiSize;
                    confettiEl.style.height = confettiSize;
                    confettiEl.style.backgroundColor = confettiBackground;

                    confettiEl.removeTimeout = setTimeout(function() {
                        confettiEl.parentNode.removeChild(confettiEl);
                    }, 3000);

                    this.containerEl.appendChild(confettiEl);
                }, 25);
            };

            window.confettiful = new Confettiful(this.$refs.confettiWrap);
        },
        stopConfetti() {
            this.$refs.confettiWrap.classList.add("disabled");
        }
    },
    watch: {
        isActive: function(newVal, oldVal) {
            if(newVal === true && oldVal === false) {
                window.amplitude.getInstance().logEvent('mint success')

                const revenue = new amplitude.Revenue().setProductId(this.tokenId.toString()).setPrice(parseFloat(this.price)).setQuantity(this.quantity);
                window.amplitude.getInstance().logRevenueV2(revenue);

                disableBodyScroll(this.$refs.popup);
                this.setSellButtonTimer(60);
                this.startConfetti();
                setTimeout(() => {
                    this.stopConfetti();
                }, 5000);
            } else if (newVal === false && oldVal === true) {
                enableBodyScroll(this.$refs.popup);
            }
        }
    },
    mounted() {
        this.contractAddress = process.env.VUE_APP_CONTRACT_ADDRESS
        this.openseaUrl = process.env.VUE_APP_OPENSEA_URL
        this.openseaAssetsUrl = process.env.VUE_APP_OPENSEA_ASSETS_URL
        this.polygonUrl = process.env.VUE_APP_POLYGON
        this.wallet = this.$store.state.wallet
        if(this.isActive) {
            disableBodyScroll(this.$refs.popup);
            this.setSellButtonTimer(60);
            this.startConfetti();
            setTimeout(() => {
                this.stopConfetti();
            }, 5000);
        } else {
            enableBodyScroll(this.$refs.popup);
        }

        const elsToTilt = document.querySelectorAll("[data-canvas-tilt]");
        UniversalTilt.init({
            elements: elsToTilt,
            settings: {
                "reverse": true,
                "shine": true,
                "shine-opacity": 0.2,
                "max": 5,
                "speed": 3000
            }
        });
    },
    unmounted() {
        const elsToTilt = document.querySelectorAll("[data-canvas-tilt]");
        elsToTilt.universalTilt.destroy();
    },
}
</script>

<style lang="scss">
    .payment_success_popup {
        max-width: 684px;
    }

    .payment_success{
        &__close {
            cursor: pointer;
            padding: 5px;
            width: 24px;
            height: 24px;
        }
        &__header{
            padding-bottom: 24px;
            margin-bottom: 24px;
            border-bottom: 1px solid #E5E5E5;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &--title{
            font-weight: bold;
            font-size: 20px;
            line-height: 28px;
            color: #253137;
        }

        &__body{
            display: flex;
            align-items: flex-start;
        }
        &__imgContainer{
            margin-right: 24px;
            width: 252px;
            flex-shrink: 0;
        }
        &__img{
            width: 100%;
        }
        &__main{
            width: 100%;
        }
        &__text{
            font-size: 16px;
            line-height: 24px;
            color: #253137;
            margin-bottom: 32px;
            a{
                color: #9E2017;
                text-decoration: none;
            }
        }
        &__button{
            margin-bottom: 12px;
        }
        &__extra_text{
            font-size: 12px;
            line-height: 16px;
            color: #545454;
            margin-bottom: 32px;
            a{
                color: #9E2017;
                text-decoration: none;
            }
        }
    }

    .share__title {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        color: #A0A0A0;
        margin-bottom: 12px;
    }
    .share_list {
        display: inline-flex;
        .share_item {
            margin-right: 12px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .share_item {
        border: 1px solid #E5E5E5;
        border-radius: 2px;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: border-color .2s;
        cursor: pointer;
        &:hover {
            border-color: #9E2017;
        }
    }

    .confetti-wrap {
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        pointer-events: none;
        z-index: 150;
        transition: opacity .4s;
    }
    .confetti-wrap.disabled {
        opacity: 0;
    }

    .confetti-container {
    perspective: 700px;
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    }

    .confetti {
    position: absolute;
    z-index: 1;
    top: -10px;
    border-radius: 0%;

    &--animation-slow {
        animation: confetti-slow 2.25s linear 1 forwards;
    }
    
    &--animation-medium {
        animation: confetti-medium 1.75s linear 1 forwards;
    }
    
    &--animation-fast {
        animation: confetti-fast 1.25s linear 1 forwards;
    }
    }

    @keyframes confetti-slow {
    0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }
    
    100% { transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg); }
    }

    @keyframes confetti-medium {
    0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }
    
    100% { transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg); }
    }

    @keyframes confetti-fast {
    0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }
    
    100% { transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg); }
    }
    @media (max-width: 991px) {
        .payment_success--title{
            font-size: 14px;
            line-height: 20px;
        }
        .payment_success__header{
            padding-bottom: 22px;
            margin-bottom: 20px;
        }
        .payment_success__body{
            flex-wrap: wrap;
        }
        .payment_success__imgContainer{
            margin-right: 0;
            margin: 0 auto;
            margin-bottom: 20px;
            width: 137px;
        }
        .payment_success__img{
            display: block;
        }
        .payment_success__text{
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 20px;
        }
        .payment_success__extra_text{
            margin-bottom: 24px;
        }
        .share{
            text-align: center;
        }
    }
</style>








