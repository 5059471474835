<template>
    <popup-layout
        :isActive="isActive"
        @click="handleClose"
        @onClose="handleClose"
        ref="popup"
        title="Select payment method"
    >
        <div class="payment_method_list">
            <div class="payment_method_list__item">
                <div
                    class="payment_method_card"
                    @click="handlePaymentMethodChoose({ paymentMethod: 'matic' })"
                >
                    <img :src="require('@/assets/images/icons/coins/ether-matic.svg')" alt="" class="payment_method_card__icon">
                    <div class="payment_method_card__title">Pay with MATIC (Polygon)</div>
                    <div class="payment_method_card__text">You’ll need to have enough Matic or eWTH in your wallet.</div>
                </div>
            </div>
            <div class="payment_method_list__item">
                <div
                    class="payment_method_card"
                    @click="handlePaymentMethodChoose({ paymentMethod: 'bank_card' })"
                >
                    <img :src="require('@/assets/images/icons/visa-mastercard.png')" alt="" class="payment_method_card__icon">
                    <div class="payment_method_card__title">Pay with bank card</div>
                    <div class="payment_method_card__text">You will pay through our partner and receive an NFT to your wallet after.</div>
                </div>
            </div>
        </div>
        <div class="payment_method_extra_text">Card payments are powered by <b>Wert</b></div>
    </popup-layout>
</template>

<script>
import PopupLayout from "@/components/PopupLayout/PopupLayout";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default {
    name: "PaymentMethod",
    props: {
        isActive: Boolean
    },
    emits: ['onClose', 'onPaymentMethodChoose'],
    components: { PopupLayout },
    methods: {
        handleClose() {
            enableBodyScroll(this.$refs.popup);
            this.$emit('onClose');
        },
        handlePaymentMethodChoose(data) {
            this.$emit('onPaymentMethodChoose', data);
        }
    },
    watch: {
        isActive: function(newVal, oldVal) {
            if(newVal === true && oldVal === false) {
                disableBodyScroll(this.$refs.popup);
            } else if (newVal === false && oldVal === true) {
                enableBodyScroll(this.$refs.popup);
            }
        }
    },
    mounted() {
        if(this.isActive) {
            disableBodyScroll(this.$refs.popup);
        } else {
            enableBodyScroll(this.$refs.popup)
        }
    }
}
</script>

<style lang="scss">
    .payment_method_list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -6px;
        margin-right: -6px;
        margin-bottom: 12px;
    }
    .payment_method_list__item {
        width: 50%;
        padding: 0 6px;
        margin-bottom: 12px;
        .payment_method_card {
            height: 100%;
        }
    }
    .payment_method_card {
        padding: 24px;
        border: 1px solid #E5E5E5;
        text-align: center;
        cursor: pointer;
        transition: border-color .2s;
        &:hover {
            border-color: #9E2017;
        }
    }
    .payment_method_card__icon {
        margin-bottom: 15px;
    }
    .payment_method_card__title {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 4px;
        color: #253137;
    }
    .payment_method_card__text {
        font-size: 16px;
        line-height: 24px;
        color: #545454;
    }
    .payment_method_extra_text {
        text-align: right;
        font-size: 16px;
        line-height: 24px;
        color: #545454;
    }

    @media (max-width: 575px) {
        .payment_method_list__item {
            width: 100%;
        }
    }
</style>