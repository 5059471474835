<template>
	<div v-html="message"></div>
</template>


<script>
export default {
	name: 'Mint50BlocksToast',
	props: {
		message: String
	}
}
</script>

<style lang="scss">
	.mintToast--link{
		border-bottom: 1px solid rgba(255, 255, 255, 0.3);
		transition: all 0.3s ease;
		text-decoration: none;
		color: #fff;
		&:hover{
			border-color: #fff;
		}
	}
</style>
