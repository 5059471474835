<template>
	<div class="container container--small">
		<div class="poweredBy">
			<div class="poweredBy--title">Powered by</div>
			<div class="poweredBy__logos">
				<div class="poweredBy__logo">
					<img :src="require('@/assets/images/logos/polygon.svg')" alt="">
				</div>
				<div class="poweredBy__logo">
					<img :src="require('@/assets/images/logos/ct.svg')" alt="">
				</div>
				<div class="poweredBy__logo">
					<img :src="require('@/assets/images/logos/mintmade.svg')" alt="">
				</div>
				<div class="poweredBy__logo">
					<img :src="require('@/assets/images/logos/opensea.svg')" alt="">
				</div>
				<div class="poweredBy__logo">
					<img :src="require('@/assets/images/logos/wert.svg')" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
	.poweredBy{
		padding-top: 80px;
		&--title{
			font-family: DM Mono;
			font-size: 32px;
			line-height: 44px;
			color: #253137;
			text-align: center;
			margin-bottom: 28px;
		}
		&__logos{
			display: flex;
			justify-content: space-between;
			align-items: center;
			&>div{
				margin-right: 55px;
				&:last-child{
					margin-right: 0;
				}
			}
		}
		&__logo{
			img{
				height: auto;
				display: block;
			}
		}
	}

	@media (max-width: 991px) {
		.poweredBy{
			padding-top: 40px;

			&--title{
				font-size: 20px;
				line-height: 28px;
				margin-bottom: 25px;
			}

			&__logos{
				display: inline-flex;
				overflow-x: scroll;
				width: 100%;
				margin-left: -16px;
				width: calc(100% + 32px);
				padding: 0;
				scrollbar-width: none;
				&:after,
				&:before {
					content: "";
					width: 16px;
					height: 1px;
					display: block;
					flex-shrink: 0;
				}
				&::-webkit-scrollbar {
					display: none;
				}
				&>div{
					flex-shrink: 0;
					img{
						max-height: 47px;
						max-width: 180px;
					}
				}
			}
		}
	}
</style>