<template>
    <div class="input_article" id="create">
        <div class="input_article__back">
            <div class="input_article__back--inner" ref="back">
                
            </div>
        </div>
        <div class="container container--small">
            <div class="input_article__header">
                <div class="input_article__preTitle">Make love not war</div>
                <div class="input_article__title">Declare your feelings on the blockchain</div>
                <div class="input_article__desc">Enter your name and the name of the person/project/whatever, and mint two NFTs on the Polygon blockchain for both of you. Let’s fill the cold OpenSea with sun-kissed waves of love.</div>
            </div>
            <div class="input_article__formContainer">
                <div class="input_article__form">
                    <form class="input_article__field_row" @submit.prevent="handleSubmit">
                        <div class="input_article__input name1" :class="{error: name1Error}">
                            <input
                                v-model="name1"
                                ref="name1"
                                type="text"
                                class="input_article__field"
                                @input="validateInput('name1')"
                                placeholder="Your Name"
                                v-on:input="onlyLat('name1')"
                                maxlength="15"
                            >
                        </div>
                        <div class="input_article__iconSelect" :class="{active: selectOpened}" ref="iconSelect">
                            <div class="input_article__iconSelect__header" @click="selectOpened = !selectOpened">
                                <img :src="require('@/assets/images/input_icons/'+ icon)" alt="">
                            </div>
                            <div class="input_article__iconSelect__body" :class="{active: selectOpened}">
                                <template v-for="(iconImage, iterator) in icons" :key="iterator">
                                    <div v-if="iconImage.icon != icon" @click="icon = iconImage.icon; iconValue = iconImage.value; selectOpened = false">
                                        <img :src="require('@/assets/images/input_icons/'+ iconImage.icon)" alt="" >
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="input_article__input name2" :class="{error: name2Error}">
                            <input
                                v-model="name2"
                                ref="name2"
                                type="text"
                                class="input_article__field"
                                @input="validateInput('name2')"
                                placeholder="Partner's Name"
                                v-on:input="onlyLat('name2')"
                                maxlength="15"
                            >
                        </div>
                        <div class="checkbox mobile">
                            <input type="checkbox" v-model="rich" id="rich2">
                            <label for="rich2">I’m rich! Add premium frame</label>
                        </div>
                    </form>
                    <button class="input_article__submit_btn" @click="handleSubmit" :class="{disable: !buttonActive}">
                        <div class="mint_button__main">
                            <span>Preview</span>
                        </div>
                    </button>
                </div>
                <div class="checkbox desktop">
                    <input type="checkbox" v-model="rich" id="rich">
                    <label for="rich">I’m rich! Add premium frame</label>
                </div>
                <span class="errorLabel" v-if="name1Error || name2Error"><img :src="require('@/assets/images/icons/alert.svg')" alt="">Please, enter names to start.</span>
            </div>
        </div>
    </div>
</template>

<script>
import router from "@/router";
import { generateNft } from "@/helpers/api.ts"

export default {
    name: "InputArticle",
    data: () => ({
        name1: "",
        name2: "",
        buttonActive: true,
        name1Error: false,
        name2Error: false,
        icon: "Heart.svg",
        iconValue: "heart",
        icons: [
            {        
                "icon": 'Heart.svg',
                "value": "heart"
            },
            {            
                "icon": 'LGBT Heart.svg',
                "value": "lgbt"
            },
            {            
                "icon": 'Like.svg',
                "value": "like"
            },
            {            
                "icon": 'Feat.svg',
                "value": "cross"
            },
            {            
                "icon": 'Wedding.svg',
                "value": "married"
            },
                        {            
                "icon": 'Peace.svg',
                "value": "peace"
            },
                        {            
                "icon": 'Peace Sign.svg',
                "value": "peace-sign"
            },
            {            
                "icon": 'Broken Heart.svg',
                "value": "broken-heart"
            }
        ],
        selectOpened: false,
        rich: false
    }),
    props: {
        title: String,
        text: String,
    },
    emits: ['onSetDetailPage'],
    created: function() {
        this.fetchIcons();
    },
    beforeUnmount: function(){
        document.removeEventListener('click', this.onClickOutside)
        window.removeEventListener('resize', this.generateBack)
        document.removeEventListener("DOMContentLoaded", this.generateBack);
    },
    mounted(){
        this.generateBack();
        document.addEventListener('click', this.onClickOutside);
        window.addEventListener('resize', this.generateBack);
        document.addEventListener("DOMContentLoaded", this.generateBack);
    },
    methods: {
        onlyLat: function(name) {
            let value
            if(name == "name1"){
                value = this.name1
            }
            if(name == "name2"){
                value = this.name2
            }

            if(value.match(/^[a-zA-Z ]+$/) == null){
                value = value.replace(/[^a-zA-Z ]/ig, "")
            }

            if(name == "name1"){
                this.name1 = value;
            }
            if(name == "name2"){
                this.name2 = value;
            }
        },
        generateBack(){
            var height = this.$refs["back"].offsetHeight;
            var width = this.$refs["back"].offsetWidth;  

            var boxCountH = Math.round(height / 10);
            var boxCountW = Math.round(width / 10);
            var boxCount = Math.round(boxCountW * boxCountH) + boxCountW + 100;

            let ToC = "";

            for(var i = 0; i < boxCount; i++) {   
                const random = Math.ceil(Math.random() * 3);
                const newLine = "<div class='backColor" + random + "'></div>";

                ToC += newLine;
            }

            this.$refs["back"].innerHTML = ToC;
        },
        onClickOutside(e){
            this.selectOpened = this.$refs["iconSelect"].contains(e.target) && this.selectOpened;
        },
        
        async handleSubmit () {
            this.validateInput("name1")
            this.validateInput("name2")
            if(this.validateInput("name1") && this.validateInput("name2")){
                this.$emit('onSetDetailPage', true)
                this.buttonActive = false;
                const data = await generateNft(this.name1, this.name2, this.iconValue, this.rich ? "1" : "0")
                router.push({path: "/" + data.ID})
                this.buttonActive = true;
            }
        },

        validateInput (name) {
            let input
            if(name == "name1"){
                input = this.$refs.name1
            }
            if(name == "name2"){
                input = this.$refs.name2
            }

            if(input.value != ""){
                if(name == "name1"){
                    this.name1Error = false
                }
                if(name == "name2"){
                    this.name2Error = false
                }
                return true;
            }

            if(name == "name1"){
                this.name1Error = true
            }
            if(name == "name2"){
                this.name2Error = true
            }

            return false
        }
    }
}
</script>

<style lang="scss">
    .input_article{
        padding: 124px 0 157px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        color: #fff;
        position: relative;
        &__formContainer{
            position: relative;
            z-index: 1;
        }

        &__back{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            z-index: -1;
            background: #000;
            &--inner{
                width: calc(100% + 20px);
                height: calc(100% + 20px);
                top: 0;
                left: 0;
                position: absolute;
                display: flex;
                flex-wrap: wrap;
            }
        }

        &__preTitle{
            font-family: Retrograde;
            font-style: italic;
            font-weight: 300;
            font-size: 18px;
            line-height: 28px;
            color: #9E2017;
            text-transform: uppercase;
            margin-bottom: 11px;
        }

        &__title{
            font-family: DM Mono;
            font-size: 42px;
            line-height: 44px;
            margin-bottom: 12px;
        }

        &__desc{
            font-size: 16px;
            line-height: 24px;
            max-width: 772px;
        }

        &__header{
            margin-bottom: 24px;
            position: relative;
            z-index: 1;
        }

        &__field_row{
            display: flex;
            align-items: flex-start;
            &>div{
                margin-right: 6px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }

        &__input{
            input {
                border: 1px solid #BCB9B9;
                border-radius: 0;
                background-color: #F7F8F8;
                color: #000;
                font-size: 16px;
                line-height: 24px;
                padding: 13px 20px;
                outline: none;
                width: 243px;
                font-family: "Open Sans";
                margin: 0;
                &::-webkit-input-placeholder {
                    color: #403C3C
                }
                &:-moz-placeholder {
                    color: #403C3C
                }
                &:-ms-input-placeholder {
                    color: #403C3C
                }
                &:focus{
                    border-color: #9E2017;
                }
            }

            svg{
                position: absolute;
                top: 0;
                right: 0;
                display: none;
            }

            &.error{
                input{
                    &::-webkit-input-placeholder {
                        color: #FF0000
                    }
                    &:-moz-placeholder {
                        color: #FF0000
                    }
                    &:-ms-input-placeholder {
                        color: #FF0000
                    }
                }
                .input_path{
                    stroke: #9E2017
                }
            }
        }

        &__iconSelect{
            border: 1px solid #BCB9B9;
            background-color: #F7F8F8;
            position: relative;
            display: flex;
            align-items: center;
            transition: all 0.3s ease;
            z-index: 2;
            img{
                width: 27px;
            }

            &:after{
                content: '';
                display: block;
                width: 14px;
                height: 14px;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.9263 1L7.9631 7.96317L0.999928 0.999999' stroke='%2314142B' stroke-width='2'/%3E%3C/svg%3E%0A");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                transition: all 0.3s ease;
                pointer-events: none;
            }

            &.active{
                &:after{
                    transform: translateY(-50%) rotateX(180deg);
                }
            }

            &__header{
                padding: 13px 43px 10px 16px;
                height: 50px;
                cursor: pointer;
            }

            &__body{
                border: 1px solid #BCB9B9;
                position: absolute;
                bottom: 0;
                left: -1px;
                width: calc(100% + 2px);
                transform: translateY(100%);
                padding: 10px 0 15px;
                background-color: #F7F8F8;
                z-index: 2;
                transition: all 0.3s ease;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                &>div{
                    padding: 6px 16px 6px;
                    transition: all 0.3s ease;
                    cursor: pointer;
                    &:hover{
                        background-color: #EFEEEF;
                    }
                    img{
                        display: block;
                    }
                }
                &.active{
                    opacity: 1;
                    visibility: visible;
                    pointer-events: auto;
                }
            }
        }

        &__form{
            display: flex;
            align-items: flex-start;
            margin-bottom: 13px;
        }

        &__submit_btn{
            margin: 0;
            margin-left: 12px;
            padding: 14px 20px;
            background: #9E2017;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border: none;
            font-family: Poppins;
            font-weight: 600;
            color: #fff;
            font-size: 16px;
            line-height: 24px;
            text-transform: uppercase;
            cursor: pointer;
            &.disable{
                opacity: .6;
                cursor: not-allowed;
                pointer-events: none;
            }
        }
    }

    .checkbox{
        position: relative;
        &.mobile{
            display: none;
        }
        input{
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            visibility: hidden;
            opacity: 0;
            &:checked{
                &+label{
                    &:after{
                        opacity: 1;
                    }
                }
            }
        }
        label{
            padding-left: 41px;
            font-size: 16px;
            line-height: 24px;
            color: #fff;
            font-family: Poppins;
            font-weight: 600;
            position: relative;
            min-height: 28px;
            display: flex;
            align-items: center;
            cursor: pointer;
            text-transform: uppercase;
            &:before{
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 24px;
                height: 24px;
                border: 2px solid #E5E5E5;
                background: #F7F8F8;
                display: block;
            }
            &:after{
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                display: block;
                opacity: 0;
                background-image: url(/img/X.svg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                width: 22px;
                height: 22px;
                left: 3px;
            }
        }
    }

    .errorLabel{
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 16px;
        line-height: 24px;
        position: absolute;
        left: 0;
        bottom: -36px;
        img{
            width: 24px;
            margin-right: 7px;
        }
    }

    @media (max-width: 991px) {
        .input_article{
            padding: 31px 0 157px;
            &__preTitle{
                display: none;
            }
            &__title{
                font-size: 33px;
                line-height: 44px;
                margin-bottom: 9px;
            }
            &__desc{
                font-size: 16px;
                line-height: 24px;
            }
            &__header{
                margin-bottom: 24px;
            }
        }
    }

    @media (min-width: 769px) {
        .errorLabel{
            display: none !important;
        }
    }

    @media (max-width: 768px) {
        .input_article{
            &__form{
                flex-wrap: wrap;
                margin-bottom: 0;
            }
            &__field_row{
                width: 100%;
                flex-wrap: wrap;
                position: relative;
                &>div{
                    margin-right: 0;
                    margin-bottom: 6px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
            &__input{
                width: 100%;
                position: relative;
                input{
                    width: calc(100% - 90px);
                }
                &:before{
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: block;
                    background-color: #F7F8F8;
                    height: 24px;
                    width: 90px;
                    border-top: 1px solid;
                    border-right: 1px solid;
                    border-bottom: 1px solid;
                    border-color: #BCB9B9;
                }
                &.name2{
                    &:before{
                        bottom: 0;
                        top: auto;
                    }
                }
            }
            &__submit_btn{
                width: 100%;
                margin-left: 0;
                margin-top: 26px;
                text-align: center;
                align-items: center;
                .mint_button__main{
                    display: block;
                }
            }
            &__iconSelect{
                position: absolute;
                top: 29px;
                right: 0;
                margin-bottom: 0 !important;
                &__header{
                    padding: 13px 42px 10px 16px;
                }
            }
        }

        .checkbox{
            &.mobile{
                display: block;
                margin-top: 3px;
            }
            &.desktop{
                display: none;
            }
        }
    }
</style>







