<template>
    <div>
        <div 
            class="article_canvas_wrap"
            :class="{'article_canvas_wrap--flipped': isFlipped}"
            @click="isFlipped = !isFlipped"
        >
            <div class="article_canvas_wrap__flip">
                <div ref="article_canvas_parent" class="article_canvas_parent" data-canvas-tilt>
                    <div v-if="hasImage === false || hasLqipImage === false">
                        <two-canvas-image :data="data" @onRender="setIsRendered(true)" @setImageBase64="setImageBase64"/>
                    </div>
                    <div v-else-if="hasImage === true || hasLqipImage === true" ref="imageParent" class="lqipImage" :class="{lazyloaded: hasImage}"></div>
                </div>
                <Back1
                    :data="data"
                    :contractInfo="contractInfo"
                />
            </div>
        </div>
    </div>
</template>

<script>
import twoCanvasImage from '@/components/twoCanvasImage/twoCanvasImage.vue';
import Back1 from '@/components/imagesBackside/Back1.vue';
import { getImage, getLqipImage } from '@/helpers/s3.ts';
import UniversalTilt from 'universal-tilt.js';

export default {
    name: "CanvasImage",
    props: {
        data: Object,
        contractInfo: Object
    },
    emits: ["onSetImageBase64", 'onSetIsRendered'],
    data: () => ({
        isFlipped: false,
        isRendered: false,
        hasImage: null,
        hasLqipImage: null,
        image: null,
        lqipImage: null,
        imageBuffer: null,
        lqipImageBuffer: null
    }),
    methods: {
        setIsRendered: function(isRendered){
            this.isRendered = isRendered
        },
        handleRender: function() {
            this.isRendered = true;
        },
        setImageBase64: function(canvas){
            this.$emit("onSetImageBase64", canvas);
        },
        async getImageFromS3(){
            const $this = this;
            this.lqipImageBuffer = await getLqipImage(this.data.id, this.data.template == "Pixel Art Premium");
            if(this.lqipImageBuffer){
                this.lqipImage = new Image()
                this.lqipImage.classList = "lqip";
                this.lqipImage.onload = () => {
                    $this.hasLqipImage = true
                    setTimeout(function(){
                        if(!$this.hasImage){
                            $this.$refs["imageParent"].appendChild($this.lqipImage)
                        }
                    }, 1);
                }
                this.lqipImage.src = "data:"+ this.lqipImageBuffer.ContentType +";base64,"+ Buffer.from(this.lqipImageBuffer.Body).toString('base64');
            } else{
                this.hasLqipImage = false
            }

            this.imageBuffer = await getImage(this.data.id, this.data.template == "Pixel Art Premium");
            if(this.imageBuffer){
                this.image = new Image()
                this.image.onload = await function() {
                    $this.hasImage = true
                    setTimeout(function(){
                        $this.$refs["imageParent"].appendChild($this.image)
                        setTimeout(function(){
                            const images = $this.$refs["imageParent"].getElementsByTagName("img");
                            for (var i = 0; i < images.length; i++) {
                                if(images[i].classList == "lqip"){
                                    images[i].remove();
                                }
                            }
                        }, 400);
                    }, 1);
                }
                this.image.src = "data:"+ this.imageBuffer.ContentType +";base64,"+ Buffer.from(this.imageBuffer.Body).toString('base64');
            } else{
                this.hasImage = false
            }

            if(this.lqipImageBuffer && this.imageBuffer){
                this.$emit("onSetImageBase64", "data:"+ this.imageBuffer.ContentType +";base64,"+ Buffer.from(this.imageBuffer.Body).toString('base64'));
            }
        }
    },
    watch: {
        hasImage: function(){
            const $this = this
            if(this.hasImage === true || this.hasLqipImage === true){
                setTimeout(function(){
                    $this.isRendered = true
                }, 100);
            }
        },
        hasLqipImage: function(){
            const $this = this
            if(this.hasImage === true || this.hasLqipImage === true){
                setTimeout(function(){
                    $this.isRendered = true
                }, 100);
            }
        },
        isRendered: function(){
            const $this = this;
            $this.$emit("onSetIsRendered", this.isRendered);
            
            setTimeout(function(){
                const elsToTilt = document.querySelectorAll("[data-canvas-tilt]");
                UniversalTilt.init({
                    elements: elsToTilt,
                    settings: {
                        "reverse": true,
                        "shine": true,
                        "shine-opacity": 0.2,
                        "max": 5,
                        "speed": 3000
                    }
                });
            }, 100);
        }
    },
    mounted(){
        this.getImageFromS3()
    },
    unmounted() {
        const elsToTilt = document.querySelectorAll("[data-canvas-tilt]");
        elsToTilt.universalTilt.destroy();
    },
    components: { twoCanvasImage, Back1 }
}
</script>

<style lang="scss">
.canvas_image__wrapper {
    position: relative;
    min-width: 200px;
    img{
        width: 100%;
    }
    canvas {
        position: relative;
    }
    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: #F9F9F9;
        transition: background .4s ease-in-out;
    }

    &.rendered {
        &:before {
            background: transparent;
        }
        .canvas_image__preloader {
            opacity: 0;
        }
    }
}
.canvas_image__preloaderContainer{
    position: relative;
    padding-bottom: 100%;
    width: 100%;
    position: relative;
    background: #F9F9F9;
}
.canvas_image__preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 40px;
    transition: opacity .4s ease-in-out;
}
.article_canvas_wrap {
    position: relative;
    transition: 0.7s;
    transform-style: preserve-3d;
    perspective: 1000;
    &.article_canvas_wrap--flipped {
        .article_canvas_wrap__flip {
            transform: rotateY(180deg);
        }
    }
}
.article_canvas_wrap__flip {
    transition: 0.7s;
    transform-style: preserve-3d;
}
@keyframes flip {
    0% {}
    100% {
        transform: rotateY(0deg)
    }
    50% {
        transform: rotateY(180deg)
    }
}
.article_canvas_parent {
    display: block;
    backface-visibility: hidden;
    overflow: hidden;
    transform: translateZ(0);
}
.lqipImage{
    -webkit-filter: blur(5px);
    filter: blur(5px);
    transition: filter 400ms, -webkit-filter 400ms;
}
.lqipImage.lazyloaded {
    -webkit-filter: blur(0);
    filter: blur(0);
}
.lqipImage{
    position: relative;
}
.lqipImage img{
    display: block;
}
.lqipImage img:nth-child(2){
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
</style>