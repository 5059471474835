<template>
    <canvas
        ref="article_canvas"
        class="article_canvas"
        width="1500"
        height="1500"
    />
</template>

<script>
import { uploadImage } from '@/helpers/s3.ts';

export default {
    name: 'two-canvas-image',
    emits: ["setImageBase64", "onRender"],
    props: {
        data: Object,
        contractInfo: Object
    },
    data: () => ({
        imageBorderType: "white", // Border variants (white or yellow)
        articleImgOnload: false,
        backOnload: false,
        iconOnload: false,
        borderOnload: false,
        canvas: {
            isRendered: false,
            articleImage: {
                bottomPosition: null
            },
            title: {
                fontSize: 93,
                lineHeight: 130,
                fontFamily: 'Retrograde',
                color: "#fff",
                bottomPosition: null,
                height: null,
            },
            description: {
                fontSize: 49,
                lineHeight: 88,
                fontFamily: 'Retrograde',
                color: "#fff",
                bottomPosition: null,
                height: null,
            }
        }
    }),
    methods: {
        async uploadImage(){
            await uploadImage(this.data.id, this.$refs["article_canvas"].toDataURL("image/png"), this.$refs["article_canvas"].width, this.$refs["article_canvas"].height, this.data.template == "Pixel Art Premium");

            this.$emit("setImageBase64", this.$refs["article_canvas"].toDataURL("image/png"));
        },
        drawImage($canvas, image, icon, border){
            if(this.backOnload && this.iconOnload && this.borderOnload){
                let ctx = $canvas.getContext('2d');

                // Отрсовка фона
                // this.drawBackground(ctx, image, $canvas);
                this.drawRandomPixelBackground(ctx);

                // Отрсовка бордера
                if(this.imageBorderType === "white")
                    this.drawBackground2(ctx, border, 26, 26, 1445, 1445);
                if(this.imageBorderType === "yellow")
                    this.drawBackground2(ctx, border, 0, 0, ctx.canvas.width, ctx.canvas.height);

                // Отрисовка имен
                this.drawTitle(ctx, this.data.name1);
                this.drawTitle2(ctx, this.data.name2);

                // Отрисовыка иконки
                this.drawLogo(ctx, icon)

                // Текст на ободке
                if(this.imageBorderType === "white"){
                    this.drawDescription(ctx, "TWO.MINTMADE.IO");
                }

                this.uploadImage()

                this.canvas.isRendered = true;
                
                this.$emit('onRender');
            }
        },
        generateImage($canvas) {
            const iconName = this.data.icon;
            var $this = this;
            let image = new Image();
            let backUrl = require('@/assets/images/nft_back.png');
            let icon = new Image();
            let iconUrl = require(`@/assets/images/nft_icons/${iconName}.svg`);
            let border = new Image();
            let borderUrl = null;

            if(this.imageBorderType === "white")
                borderUrl = require(`@/assets/images/nft_border2.png`);
            if(this.imageBorderType === "yellow")
                borderUrl = require(`@/assets/images/nft_border.svg`);

            image.onload = () => {
                $this.backOnload = true;
                $this.drawImage($canvas, image, icon, border)
            }
            icon.onload = () => {
                $this.iconOnload = true;
                $this.drawImage($canvas, image, icon, border)
            }
            border.onload = () => {
                $this.borderOnload = true;
                $this.drawImage($canvas, image, icon, border)
            }

            image.crossOrigin = "anonymous";
            icon.crossOrigin = "anonymous";
            icon.crossOrigin = "borderUrl";
            image.src = backUrl;
            icon.src = iconUrl;
            border.src = borderUrl;
        },

        drawBackground(ctx, image, $canvas) {
            this.drawImageProp(ctx, image, 0, 0, $canvas.width, $canvas.height)
        },

        drawBackground2(ctx, image, x, y, width, height) {
            this.drawImageProp(ctx, image, x, y, width, height)
        },

        drawLogo(ctx, image) {
            const renderHeight = 200;
            const renderWidth = image.width * (renderHeight / image.height);

            ctx.drawImage(image, ctx.canvas.width / 2 - renderWidth / 2, ctx.canvas.height / 2 - renderHeight / 2, renderWidth, renderHeight)
        },

        drawTitle(ctx, title) {
            let xPos = 750;
            let yPos;
            let textWidth;

            if(this.imageBorderType === "white") textWidth = 1250;
            if(this.imageBorderType === "yellow") textWidth = 1050;

            if (this.canvas.articleImage.bottomPosition) yPos = this.canvas.articleImage.bottomPosition + 170;
            else yPos = 423;

            ctx.textAlign = "center";
            ctx.font = `normal ${this.canvas.title.fontSize}px '${this.canvas.title.fontFamily}', sans-serif`;
            ctx.fillStyle = this.canvas.title.color;
            const linesCount = ctx.fillText(title, xPos, yPos, textWidth);
            this.canvas.title.bottomPosition = linesCount * this.canvas.title.lineHeight + yPos;
            this.canvas.title.height = linesCount * this.canvas.title.lineHeight;
        },

        drawTitle2(ctx, title) {
            let xPos = 750;
            let yPos;
            let textWidth;

            if(this.imageBorderType === "white") textWidth = 1250;
            if(this.imageBorderType === "yellow") textWidth = 1050;

            if (this.canvas.articleImage.bottomPosition) yPos = this.canvas.articleImage.bottomPosition + 170;
            else yPos = 1120;

            ctx.textAlign = "center";
            ctx.font = `normal ${this.canvas.title.fontSize}px '${this.canvas.title.fontFamily}', sans-serif`;
            ctx.fillStyle = this.canvas.title.color;
            const linesCount = ctx.fillText(title, xPos, yPos, textWidth);
            this.canvas.title.bottomPosition = linesCount * this.canvas.title.lineHeight + yPos;
            this.canvas.title.height = linesCount * this.canvas.title.lineHeight;
        },

        drawDescription(ctx, text) {
            let xPos = 769;
            let yPos = 1473;

            ctx.font = `900 ${this.canvas.description.fontSize}px '${this.canvas.description.fontFamily}', sans-serif`;
            ctx.fillStyle = this.canvas.description.color;
            
            const linesCount = this.drawWrapedText(ctx, text, xPos, yPos, 1350, this.canvas.description.lineHeight);
            this.canvas.description.bottomPosition = linesCount * this.canvas.description.lineHeight + yPos;
            this.canvas.description.height = linesCount * this.canvas.description.lineHeight;
        },

        drawRandomPixelBackground(ctx) {
            var colors = ["#141414", "#000000", "#090A0A"];
            var height = ctx.canvas.height;
            var width = ctx.canvas.width;  
            var pixelWidth = 35;
            var pixelHeight = 35;

            var boxCountH = Math.round(height / pixelHeight);
            var boxCountW = Math.round(width / pixelWidth);
            var boxCount = Math.round(boxCountW * boxCountH) + boxCountW;

            var pixelTop = 0;
            var pixelLeft = 0;
            for(var i = 0; i < boxCount*2; i++) {
                var random = Math.ceil(Math.random() * 3 - 1);
                var randomColor = colors[random];

                ctx.fillStyle = randomColor;
                ctx.fillRect(pixelLeft, pixelTop, pixelWidth, pixelHeight);

                if(pixelLeft >= width) {
                    pixelLeft = 0;
                    pixelTop += pixelWidth;
                } else {
                    pixelLeft += pixelHeight;
                }
            }
        },

        drawWrapedText(context, text, x, y, maxWidth, lineHeight) {
            var words = text.split(' ');
            var line = '';
            var linesCount = 0;

            for(var n = 0; n < words.length; n++) {
                var testLine = line + words[n] + ' ';
                var metrics = context.measureText(testLine);
                var testWidth = metrics.width;
                if (testWidth > maxWidth && n > 0) {
                    context.fillText(line, x, y);
                    line = words[n] + ' ';
                    y += lineHeight;
                    linesCount += 1;
                }
                else {
                    line = testLine;
                }
            }
            context.fillText(line, x, y);
            linesCount += 1;
            return linesCount;
        },

        drawImageProp(ctx, img, x, y, w, h, offsetX, offsetY) {
            if (arguments.length === 2) {
                x = y = 0;
                w = ctx.canvas.width;
                h = ctx.canvas.height;
            }

            // default offset is center
            offsetX = typeof offsetX === "number" ? offsetX : 0.5;
            offsetY = typeof offsetY === "number" ? offsetY : 0.5;

            // keep bounds [0.0, 1.0]
            if (offsetX < 0) offsetX = 0;
            if (offsetY < 0) offsetY = 0;
            if (offsetX > 1) offsetX = 1;
            if (offsetY > 1) offsetY = 1;

            var iw = img.width,
                ih = img.height,
                r = Math.min(w / iw, h / ih),
                nw = iw * r,   // new prop. width
                nh = ih * r,   // new prop. height
                cx, cy, cw, ch, ar = 1;

            // decide which gap to fill    
            if (nw < w) ar = w / nw;                             
            if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
            nw *= ar;
            nh *= ar;

            // calc source rectangle
            cw = iw / (nw / w);
            ch = ih / (nh / h);

            cx = (iw - cw) * offsetX;
            cy = (ih - ch) * offsetY;

            // make sure source rectangle is valid
            if (cx < 0) cx = 0;
            if (cy < 0) cy = 0;
            if (cw > iw) cw = iw;
            if (ch > ih) ch = ih;

            // fill image in dest. rectangle
            ctx.drawImage(img, cx, cy, cw, ch,  x, y, w, h);
        }
    },
    mounted() {
        if(this.data.template == "Pixel Art Premium"){
            this.imageBorderType = "yellow"
        }
        setTimeout(() => {
            this.generateImage(this.$refs.article_canvas);
        }, 100);
    }
}
</script>