<template>
    <popup-layout
        pupupContentClass="payment_method_confirm_popup"
        :isActive="isActive"
        @click="handleClose"
        @onClose="handleClose"
        ref="popup"
    >
        <div class="iframeCardContainer">
            <div class="iframeCardLoader">
                <img :src="require('@/assets/images/icons/preloader.svg')" alt="" class="preloader__img">
            </div>
            <div id="iframeCard" class="iframeCard"></div>
        </div>
                
        <div class="payment_confirm_footer">
            <div class="payment_confirm_extra_text">Card payments are powered by <b>Wert</b></div>
            <div class="payment_confirm_cancel" @click="handleClose">Cancel</div>
        </div>
    </popup-layout>
</template>

<script>
import PopupLayout from "@/components/PopupLayout/PopupLayout";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import WertWidget from '@wert-io/widget-initializer';
import { Nft } from "@/helpers/nft.ts"
import contract_abi from "@/assets/json/contract_abi.json"
import Web3EthContract from "web3-eth-contract";
import { signSmartContractData } from '@wert-io/widget-sc-signer';
import { v4 as uuidv4 } from 'uuid';
import { wertTransaction } from "@/helpers/api.ts"
import { Buffer } from 'buffer/';
import { useToast } from "vue-toastification";
import SuccessMintToast from "@/components/SuccessMintToast.vue";

export default {
    name: "PaymentMethodConfirm",
    props: {
        isActive: Boolean,
        nftModel: Nft
    },
    data: () => ({
        checkInterval: null,
        pending: false,
        success: false
    }),
    emits: ['onClose', 'onSuccess', 'onSetTransaction'],
    components: { PopupLayout },
    methods: {
        handleClose() {
            enableBodyScroll(this.$refs.popup)
            this.$emit('onClose')
        },
        startInterval(click_id){
            const $this = this;
            this.checkInterval = setInterval(async function(){
                const data = await wertTransaction(click_id);
                if(data != null && data.type == "order_complete"){
                    $this.success = true
                    $this.stopInterval()
                    $this.$emit("onSetTransaction", data.order.transaction_id)

                    if(this.isActive){
                        $this.$emit("onSuccess")
                    } else {
                        const toast = useToast();
                        const content = {
                            component: SuccessMintToast,
                            listeners: {
                                openSuccessEmit: function(){
                                    $this.$emit("onSuccess")
                                }
                            }
                        };
                        toast.success(content);
                    }

                    $this.handleClose()
                    $this.nftModel.getNftData($this.nftModel.premium);
                }
                if(data != null && data.type == "payment_started"){
                    $this.pending = true
                }
                if(data != null && data.type == "order_canceled"){
                    $this.stopInterval()
                }
            }, 2000);
        },
        stopInterval(){
            if(this.checkInterval != null){
                clearInterval(this.checkInterval)
                this.checkInterval = null
            }
        },
        async createWertWidget(){
            const container = document.getElementById("iframeCard");
            container.innerHTML = "";

            const wallet = this.$store.state.wallet

            const $this = this

            const nft = new Nft();
            await nft.updateNftId(wallet.currentNftId, $this.nftModel.premium);

            if(nft.nftData.totalSupply == nft.nftData.maxSupply){
              $this.handleClose();
              alert("Нет свободных копий");
            } else {
                // const web3 = new Web3()
                const SmartContractObj = await new Web3EthContract(
                    contract_abi,
                    process.env.VUE_APP_CONTRACT_ADDRESS
                );

                let wertWidget
                let signedData
                let otherWidgetOptions
                const click_id = uuidv4()

                if(process.env.NODE_ENV == "dev"){
                    signedData = signSmartContractData({
                        address: wallet.wallet.address,
                        commodity: 'MATIC',
                        commodity_amount: nft.nftData.price,
                        pk_id: 'key1',
                        sc_address: process.env.VUE_APP_CONTRACT_ADDRESS,
                        sc_id: uuidv4(),
                        sc_input_data: SmartContractObj.methods.mint(wallet.wallet.address, wallet.currentNftId, 1).encodeABI(),
                    }, process.env.VUE_APP_WERT_PRIVATE);

                    otherWidgetOptions = {
                        partner_id: '01FPWW7VC4Z68RGV12XD5MPVBG',
                        container_id: 'iframeCard',
                        click_id: click_id,
                        origin: 'https://sandbox.wert.io',
                        currency: "USD"
                    };
                } else {
                    signedData = signSmartContractData({
                        address: wallet.wallet.address,
                        commodity: 'MATIC',
                        commodity_amount: nft.nftData.price,
                        pk_id: 'key1',
                        sc_address: process.env.VUE_APP_CONTRACT_ADDRESS,
                        sc_id: uuidv4(),
                        sc_input_data: SmartContractObj.methods.mint(wallet.wallet.address, wallet.currentNftId, 1).encodeABI(),
                    }, process.env.VUE_APP_WERT_PRIVATE);

                    otherWidgetOptions = {
                        partner_id: '01FQGXM9E696P6XDYXJTEDDC9M',
                        container_id: 'iframeCard',
                        click_id: click_id,
                        currency: "USD"
                    };
                }

                wertWidget = new WertWidget({
                    ...signedData,
                    ...otherWidgetOptions,
                });

                wertWidget.mount();

                this.startInterval(click_id);
            }
        }
    },
    watch: {
        isActive: function(newVal, oldVal) {
            if(newVal === true && oldVal === false) {
                window.amplitude.getInstance().logEvent('open wert modal')
                this.stopInterval();
                disableBodyScroll(this.$refs.popup);
                this.createWertWidget();
            } else if (newVal === false && oldVal === true) {
                // this.stopInterval();
                enableBodyScroll(this.$refs.popup);
            }
        }
    },
    mounted() {
        window.Buffer = Buffer;
        if(this.isActive) {
            this.stopInterval();
            disableBodyScroll(this.$refs.popup)
        } else {
            enableBodyScroll(this.$refs.popup)
            // this.stopInterval();
        }
    }
}
</script>

<style lang="scss">
    .iframeCardContainer{
        flex: 1;
        position: relative;
        overflow: hidden;
    }
    .iframeCard{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin-top: -32px;
        margin-left: -48px;
        margin-right: -48px;
        width: calc(100% + 96px);
    }
    .iframeCardLoader{
        &__img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-width: 40px;
            transition: opacity .4s ease-in-out;
        }
    }
    .payment_confirm_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
    }
    .payment_confirm_cancel {
        cursor: pointer;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #545454;
    }
    .payment_confirm_extra_text {
        text-align: right;
        font-size: 16px;
        line-height: 24px;
        color: #545454;
    }
    .payment_method_confirm_popup {
        max-width: 839px;
        min-height: 793px;
        display: inline-flex;
        flex-direction: column;
    }

    @media (max-width:752px) {
        .iframeCard{
            margin-top: -16px;
            margin-left: -24px;
            margin-right: -24px;
            width: calc(100% + 48px);
        }
    }

    @media (max-width: 575px) {
        .payment_confirm_footer {
            flex-direction: column;
            align-items: flex-start;
        }
        .payment_confirm_extra_text {
            margin-bottom: 12px;
        }
    }
</style>
