<template>
    <popup-layout :isActive="isActive" @click="handleClose" ref="popup">
        <div class="pay_proccess">
            <div class="pay_proccess__head">
                Complete your mintng
                <img :src="require('@/assets/images/icons/cross-dark.svg')" class="pay_proccess__cross" @click="handleClose">
            </div>
            <div class="pay_proccess__preview">
                <div class="mint_preview">
                    <img :src="preview.image" class="mint_preview__img" alt="">
                    <div class="mint_preview__main">
                        <div class="mint_preview__main_content">
                            <div class="mint_preview__title">”{{preview.name1}} <img :src="require('@/assets/images/input_icons/' + preview.icon + '.svg')" alt=""> {{preview.name2}}”&nbsp;<span>x2</span></div>
                            <div class="mint_preview__desc">2 unique NFTs (for you and your partner) to declare your feelings on Polygon blockchain</div>
                        </div>
                        <div class="mint_preview__main_right">
                            <div class="mint_preview__label">You pay</div>
                            <div class="mint_preview__cost">{{preview.cost}}</div>
                            <div class="mint_preview__currency">≈ {{preview.costInCurrency}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pay_proccess__steps">
                <div class="progress_steps">
                    <div
                        class="progress_step"
                        :class="{
                            'active': activeStep === 1,
                            'success': activeStep > 1,
                            'error': stepError && stepError.step === 1
                        }"
                    >
                        <div class="progress_step__head">
                            <div class="progress_step__circle_wrap">
                                <svg width="42" height="42" class="progress_step__circle">
                                    <circle
                                        r="18" cx="50%" cy="50%" fill="transparent" stroke-dasharray="565.48" stroke-dashoffset="0" stroke="#E5E5E5" stroke-width="1px"></circle>
                                    <circle r="18" cx="50%" cy="50%" fill="transparent" stroke-dasharray="565.48" stroke="#9E2017" stroke-width="4px" ref="progressCircle"></circle>
                                </svg>
                                <div class="progress_step__circle_title">1</div>
                            </div>
                            <div class="progress_step__title">Approve transaction</div>
                        </div>
                        <div class="progress_step__description">
                            <div v-if="stepError && stepError.error && stepError.step === 1" class="progress_step__error">
                                <img :src="require('@/assets/images/icons/danger.svg')" alt="">
                                <span v-html="stepError.error"></span>
                            </div>
                            <div v-else>Please, follow your wallet instructions to approve the payment.</div>
                        </div>
                    </div>
                    <div
                        class="progress_step"
                        :class="{
                            'active': activeStep === 2,
                            'success': activeStep > 2,
                            'error': stepError && stepError.step === 2
                        }"
                    >
                        <div class="progress_step__head">
                            <div class="progress_step__circle_wrap">
                                <svg width="42" height="42" class="progress_step__circle">
                                    <circle
                                        r="18" cx="50%" cy="50%" fill="transparent" stroke-dasharray="565.48" stroke-dashoffset="0" stroke="#E5E5E5" stroke-width="1px"></circle>
                                    <circle r="18" cx="50%" cy="50%" fill="transparent" stroke-dasharray="565.48" stroke="#9E2017" stroke-width="4px" ref="progressCircle"></circle>
                                </svg>
                                <div class="progress_step__circle_title">2</div>
                            </div>
                            <div class="progress_step__title">Pending transaction</div>
                        </div>
                        <div class="progress_step__description">
                            <div v-if="stepError && stepError.error && stepError.step === 2" class="progress_step__error">
                                <img :src="require('@/assets/images/icons/danger.svg')" alt="">
                                <span>{{stepError.error}}</span>
                            </div>
                            <div v-else>
                                It may take few minutes to receive your transaction. If you'll accidentally close this window, check your wallet or OpenSea account to find your NFTs.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </popup-layout>
</template>

<script>
import PopupLayout from "@/components/PopupLayout/PopupLayout";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default {
    name: "PaymentProcess",
    props: {
        isActive: Boolean,
        preview: Object,
        activeStep: Number,
        tokenId: Number,
        stepError: Object,
        name1: String,
        name2: String,
        icon: String
    },
    emits: ['onClose'],
    data: () => ({
        contractAddress: ""
    }),
    components: { PopupLayout },
    methods: {
        getProgressStrokeDashoffset(val) {
            if (isNaN(val)) {
                val = 100;
            } else {
                var r = this.$refs.progressCircle.attr('r');
                var c = Math.PI*(r*2);
            
                if (val < 0) { val = 0;}
                if (val > 100) { val = 100;}
                
                var pct = ((100-val)/100)*c;
            }
            return pct;
        },
        handleClose() {
            enableBodyScroll(this.$refs.popup);
            this.$emit('onClose');
        }
    },
    watch: {
        isActive: function(newVal, oldVal) {
            this.$store.state.wallet.payPopupOpened = newVal
            if(newVal === true && oldVal === false) {
                disableBodyScroll(this.$refs.popup);
            } else if (newVal === false && oldVal === true) {
                enableBodyScroll(this.$refs.popup);
            }
        },
        activeStep: function(newVal) {
            if(newVal === 3) {
                this.startConfetti();
                setTimeout(() => {
                    this.stopConfetti();
                }, 5000);
            }
        }
    },
    mounted() {
        this.contractAddress = process.env.VUE_APP_CONTRACT_ADDRESS

        if(this.isActive) {
            disableBodyScroll(this.$refs.popup);
        } else {
            enableBodyScroll(this.$refs.popup)
        }
        if(this.activeStep === 3) {
            this.startConfetti();
            setTimeout(() => {
                this.stopConfetti();
            }, 5000);
        }
    }
}
</script>

<style lang="scss">
.pay_proccess__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #E5E5E5;
}
.pay_proccess__cross {
    padding: 5px;
    cursor: pointer;
}
.pay_proccess__preview {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #E5E5E5;
}

.mint_preview {
    display: flex;
    align-items: flex-start;
}
.mint_preview__img {
    width: 80px;
    height: auto;
    margin-right: 12px;
    flex-shrink: 0;
}
.mint_preview__main {
    display: flex;
    flex-grow: 1;
}
.mint_preview__main_content {
    margin-right: 15px;
    max-width: 246px;
}
.mint_preview__main_right {
    margin-left: auto;
    flex-shrink: 0;
    text-align: right;
}
.mint_preview__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
    color: #253137;
    text-transform: uppercase;
    display: inline-flex;
    &:last-child {
        margin-bottom: 0;
    }
    img{
        max-height: 20px;
        margin-left: 5px;
        margin-right: 5px;
    }
    span{
        color: #9E2017;
    }
}
.mint_preview__desc {
    color: #545454;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 12px;
    &:last-child {
        margin-bottom: 0;
    }
}
.mint_preview__edition {
    font-size: 14px;
    line-height: 20px;
    color: #253137;
}
.mint_preview__label {
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #A0A0A0;
    margin-bottom: 4px;
    &:last-child {
        margin-bottom: 0;
    }
}
.mint_preview__cost {
    font-size: 20px;
    line-height: 28px;
    color: #253137;
    margin-bottom: 4px;
    font-weight: bold;
    &:last-child {
        margin-bottom: 0;
    }
}
.mint_preview__currency {
    font-size: 14px;
    line-height: 20px;
    color: #36474F;
}

.progress_step {
    border: 1px solid #E5E5E5;
    border-top: 0;
    padding: 24px;
    &:first-child {
        border-top: 1px solid #E5E5E5;
    }
    circle {
        transition: stroke-dashoffset 1s ease, stroke .5s ease
    }
    circle:nth-child(2) {
        stroke-width: 0;
        stroke-dashoffset: 566px;
        transform: rotate(-90deg);
        transform-origin: center;
    }
}
.progress_step.active {
    circle:nth-child(2) {
        stroke: #9E2017;
        stroke-width: 4px;
        stroke-dashoffset: 510px
    }
    .progress_step__description {
        display: block;
    }
    .progress_step__title, .progress_step__circle_title {
        color: #253137;
    }
}
.progress_step.active:not(.error):not(.success) .progress_step__circle {
    animation: activeCircle 3s linear infinite;
}
.progress_step.success {
    circle:nth-child(2) {
        stroke: #43EB19;
        stroke-width: 4px;
        stroke-dashoffset: 452px;
    }
    .progress_step__title, .progress_step__circle_title {
        color: #253137;
    }
}
.progress_step.error {
    circle:nth-child(2) {
        stroke: #ED0000;
        stroke-width: 4px;
        stroke-dashoffset: 452px;
    }
    .progress_step__circle_title {
        color: #ED0000;
    }
    .progress_step__description {
        display: block;
    }
    .progress_step__title {
        color: #253137;
    }
}
.progress_step__head {
    display: flex;
    align-items: center;
}
.progress_step__circle_wrap {
    position: relative;
    margin-right: 12px;
}
.progress_step__circle {
    display: block;
}
.progress_step__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #999999;
}
.progress_step__circle_title {
    font-size: 16px;
    color: #999999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}
.progress_step__description {
    border-top: 1px solid #E5E5E5;
    margin-top: 24px;
    padding-top: 24px;
    font-size: 16px;
    line-height: 24px;
    color: #253137;
    display: none;

    a{
        color: #9E2017;
        text-decoration: none;
        &:hover {
            color: inherit;
        }
    }
}
.progress_step__error {
    display: flex;
    align-items: flex-start;
    img {
        margin-right: 10px;
        width: 16px;
        position: relative;
        bottom: -3px;
    }
}
.progress_step__buttons {
    display: flex;
    margin-top: 24px;
}
.progress_step__button {
    height: 52px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #253137 !important;
    font-family: Poppins;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-right: 16px;
    background-color: #9E2017;
    min-width: 200px;
    cursor: pointer;
    transition: background-color .2s, box-shadow .2s;
    &:hover {
        background-color: rgba(250, 191, 44, 0.7);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }
    &:last-child {
        margin-right: 0;
    }
    img {
        margin-right: 10px;
    }
}
.progress_step__button--blue {
    color: #fff;
    background-color: #3999FA;
}

@media (max-width: 991px) {
    .pay_proccess__head {
        font-size: 14px;
        line-height: 20px;
    }
    .mint_preview__main {
        flex-direction: column;
    }
    .mint_preview__main_content {
        margin-right: 0;
    }
    .mint_preview__main_right {
        margin-left: 0;
        text-align: left;
        margin-top: 12px;
    }
    .mint_preview__cost {
        margin-bottom: 0;
        display: inline-block;
    }
    .mint_preview__currency {
        display: inline-block;
        margin-left: 4px;
    }
    .progress_step {
        padding: 20px 16px;
    }
    .progress_step__circle {
        width: 32px;
        height: 32px;
    }
    .progress_step circle {
        r: 14px
    }
    .progress_step circle:nth-child(2) {
        stroke-width: 2px !important;
    }
    .progress_step__circle_title {
        font-size: 12px;
        line-height: 12px;
    }
    .progress_step__title {
        font-size: 14px;
        line-height: 20px;
    }
    .progress_step__description {
        font-size: 12px;
        line-height: 16px;
    }
    .progress_step__description {
        padding-top: 20px;
        margin-top: 20px;
    }
    .progress_step.active circle:nth-child(2) {
        stroke-dashoffset: 520px;
    }
    .progress_step.success circle:nth-child(2) {
        stroke-dashoffset: 470px;
    }
    .progress_step__buttons {
        margin-top: 16px;
    }
    .progress_step__button {
        min-width: 123px;
        margin-right: 10px;
    }
    .progress_step__error img {
        bottom: -2px;
    }
}

@keyframes activeCircle {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
