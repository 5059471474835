// @ts-nocheck
function copyTextToClipboard(textToCopy) {
    let textArea;

    function isOS() {
        //can use a better detection logic here
        return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
        textArea = document.createElement('textarea');
        textArea.readOnly = true;
        textArea.contentEditable = true;
        textArea.value = text;
        if (document.querySelector('.mfp-content')) {
            document.querySelectorAll('.mfp-content')[0].appendChild(textArea);
        } else {
            document.body.appendChild(textArea);
        }
    }

    function selectText() {
        let range, selection;

        if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
        } else {
            textArea.select();
        }
    }

    function copyTo() {
        document.execCommand('copy');
        if (document.querySelector('.mfp-content')) {
            document.querySelectorAll('.mfp-content')[0].removeChild(textArea);
        } else {
            document.body.removeChild(textArea);
        }
    }

    createTextArea(textToCopy);
    selectText();
    copyTo();
}

export default copyTextToClipboard;